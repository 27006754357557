import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from'react-router-dom';

const slides = [
  {
    bgImage: '/assets/hero1.jpg',
    title: 'Capture Every Moment',
    subtitle: 'With our premium cameras',
    buttonText: 'Shop Now',
    buttonLink: '/category/cameras',
    image: '/assets/camera1.png',
  },
  {
    bgImage: '/assets/hero2.jpg',
    title: 'Professional Lenses',
    subtitle: 'For stunning photography',
    buttonText: 'Explore Lenses',
    buttonLink: '/category/lenses',
    image: '/assets/lens1.png',
  },
  {
    bgImage: '/assets/hero3.jpg',
    title: 'Smooth Video Capture',
    subtitle: 'With our advanced gimbals',
    buttonText: 'View Gimbals',
    buttonLink: '/category/gimbals',
    image: '/assets/gimbal1.png',
  },
];

const CustomHeroSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 8000); // Increased to 8 seconds
      return () => clearInterval(timer);
    }, []);
  
    const nextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };
  
    const prevSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };
  
    return (
      
      <div 
        className="relative w-full h-[500px] overflow-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <AnimatePresence initial={false}>
          <motion.div
            key={currentSlide}
            className="absolute inset-0 bg-cover bg-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ backgroundImage: `url(${slides[currentSlide].bgImage})` }}
          />
        </AnimatePresence>
  
        {/* Add a white overlay */}
        <div className="absolute inset-0 bg-white bg-opacity-50" />
  
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-full max-w-3xl px-4 sm:px-8 md:px-12 lg:px-16 text-center">
            <AnimatePresence mode="wait">
              <motion.h1
                key={`title-${currentSlide}`}
                className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-black mb-4 sm:mb-6"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
              >
                {slides[currentSlide].title}
              </motion.h1>
            </AnimatePresence>
            <AnimatePresence mode="wait">
              <motion.p
                key={`subtitle-${currentSlide}`}
                className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-700 mb-6 sm:mb-8"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.8, delay: 0.2, ease: "easeInOut" }}
              >
                {slides[currentSlide].subtitle}
              </motion.p>
            </AnimatePresence>
            <AnimatePresence mode="wait">
              <motion.button
                key={`button-${currentSlide}`}
                className="bg-transparent text-white px-6 py-3 sm:px-8 sm:py-4 text-sm sm:text-base md:text-lg rounded-full font-semibold hover:bg-opacity-90 transition-colors"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.8, delay: 0.6, ease: "easeInOut" }}
              >
                <Link
                to={slides[currentSlide].buttonLink}
                className="inline-block bg-black text-white px-6 py-3 sm:px-8 sm:py-4 text-sm sm:text-base md:text-lg rounded-full font-semibold hover:bg-opacity-90 transition-colors"
                >
                {slides[currentSlide].buttonText}
                </Link>
              </motion.button>
            </AnimatePresence>
          </div>
        </div>
  
        <AnimatePresence>
          {isHovered && (
            <>
              <motion.button
                className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-30 hover:bg-opacity-50 rounded-full p-2 sm:p-3 text-black transition-all duration-300"
                onClick={prevSlide}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.5 }}
              >
                <FaChevronLeft size={16} className="sm:hidden" />
                <FaChevronLeft size={24} className="hidden sm:block" />
              </motion.button>
              <motion.button
                className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-30 hover:bg-opacity-50 rounded-full p-2 sm:p-3 text-black transition-all duration-300"
                onClick={nextSlide}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.5 }}
              >
                <FaChevronRight size={16} className="sm:hidden" />
                <FaChevronRight size={24} className="hidden sm:block" />
              </motion.button>
            </>
          )}
        </AnimatePresence>
      </div>
    );
  };
  
  export default CustomHeroSlider;
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import axios from 'axios';

export const withGlobalErrorHandler = (WrappedComponent) => {
  return function WithGlobalErrorHandler(props) {
    const context = useContext(AppContext);
    
    useEffect(() => {
      const interceptor = axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401 && context && context.logout) {
            context.logout();
            console.error('Your session has expired. Please log in again.');
            // You can also use a toast notification library here to show a message to the user
          }
          return Promise.reject(error);
        }
      );

      return () => {
        axios.interceptors.response.eject(interceptor);
      };
    }, [context]);

    return <WrappedComponent {...props} />;
  };
};

// Keep the existing functions as well
export const handleApiResponse = (response, logout) => {
  if (response.status === 401) {
    logout();
    throw new Error('Your session has expired. Please log in again.');
  }
  return response;
};

export const useApiErrorHandler = () => {
  const context = useContext(AppContext);
  
  return (error) => {
    if (error.response && error.response.status === 401 && context && context.logout) {
      context.logout();
      console.error('Your session has expired. Please log in again.');
    }
    throw error;
  };
};
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ProductCardWithHover from '../components/ProductCardWithHover';
import { ChevronRightIcon } from '@heroicons/react/solid';
import products from '../data/products';

const AllProductsPage = () => {
  const { category } = useParams();
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    brand: '',
    category: '',
    minPrice: '',
    maxPrice: '',
    minRating: 0,
  });
  const [sortOption, setSortOption] = useState('default');

  useEffect(() => {
    const fetchCategoryProducts = () => {
      setLoading(true);
      setError(null);
      try {
        const allProducts = category ? 
          products.filter(product => belongsToCategory(product, category)) : 
          products;

        setCategoryProducts(allProducts);
        setFilteredProducts(allProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('An error occurred while fetching products.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryProducts();
  }, [category]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sortOption, categoryProducts]);

  const belongsToCategory = (product, targetCategory) => {
    const productCategory = product.category.toLowerCase();
    const target = targetCategory.toLowerCase();

    if (target === 'cameras' && productCategory === 'camera') return true;
    if (target === 'lenses' && productCategory === 'lens') return true;
    if (target === 'gimbals' && productCategory === 'gimbal') return true;

    if (target === 'accessories' && 
        !['camera', 'lens', 'gimbal'].includes(productCategory) &&
        productCategory !== 'accessories') {
      return true;
    }

    return productCategory.includes(target);
  };

  const applyFiltersAndSort = () => {
    let result = [...categoryProducts];

    // Apply filters
    result = result.filter(product => {
      return (
        (filters.brand ? product.brand === filters.brand : true) &&
        (filters.category ? product.category === filters.category : true) &&
        (filters.minPrice ? product.price >= parseFloat(filters.minPrice) : true) &&
        (filters.maxPrice ? product.price <= parseFloat(filters.maxPrice) : true) &&
        (filters.minRating ? product.rating >= filters.minRating : true)
      );
    });

    // Apply sorting
    switch (sortOption) {
      case 'price-low-high':
        result.sort((a, b) => a.price - b.price);
        break;
      case 'price-high-low':
        result.sort((a, b) => b.price - a.price);
        break;
      case 'name-a-z':
        result.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-z-a':
        result.sort((a, b) => b.name.localeCompare(a.name));
        break;
      default:
        // No sorting
    }

    setFilteredProducts(result);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  // Get unique categories and brands
  const categories = [...new Set(products.map(product => product.category))];
  const brands = [...new Set(products.map(product => product.brand))];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-4">
        <Link to="/" className="text-gray-600 hover:text-gray-800">
          Home
        </Link>
        <ChevronRightIcon className="h-5 w-5 mx-2 text-gray-400" />
        <span className="text-gray-800 font-semibold">{category || 'All Products'}</span>
      </div>

      <h1 className="text-3xl font-bold mb-6">{category || 'All Products'}</h1>

      <div className="flex flex-col md:flex-row md:space-x-6">
      {/* Filters */}
      <div className="w-full md:w-60 mb-4 md:mb-0 md:sticky md:top-20 md:self-start" style={{ height: 'fit-content' }}>
          <h2 className="text-xl font-semibold mb-2">Filters</h2>
          
          {/* Brand Filter */}
          <div className="mb-4">
            <label className="block mb-2">Brand</label>
            <select 
              className="w-full p-2 border rounded"
              onChange={(e) => handleFilterChange('brand', e.target.value)}
              value={filters.brand}
            >
              <option value="">All Brands</option>
              {brands.map(brand => (
                <option key={brand} value={brand}>{brand}</option>
              ))}
            </select>
          </div>

          {/* Category Filter */}
          <div className="mb-4">
            <label className="block mb-2">Category</label>
            <select 
              className="w-full p-2 border rounded"
              onChange={(e) => handleFilterChange('category', e.target.value)}
              value={filters.category}
            >
              <option value="">All Categories</option>
              {categories.map(cat => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>

          {/* Price Range Filter */}
          <div className="mb-4">
            <label className="block mb-2">Price Range</label>
            <div className="flex space-x-2">
              <input
                type="number"
                placeholder="Min"
                className="w-1/2 p-2 border rounded"
                onChange={(e) => handleFilterChange('minPrice', e.target.value)}
                value={filters.minPrice}
              />
              <input
                type="number"
                placeholder="Max"
                className="w-1/2 p-2 border rounded"
                onChange={(e) => handleFilterChange('maxPrice', e.target.value)}
                value={filters.maxPrice}
              />
            </div>
          </div>

          {/* Rating Filter */}
          <div className="mb-4">
            <label className="block mb-2">Minimum Rating</label>
            <select 
              className="w-full p-2 border rounded"
              onChange={(e) => handleFilterChange('minRating', parseInt(e.target.value))}
              value={filters.minRating}
            >
              <option value={0}>All Ratings</option>
              <option value={1}>1 Star & Above</option>
              <option value={2}>2 Stars & Above</option>
              <option value={3}>3 Stars & Above</option>
              <option value={4}>4 Stars & Above</option>
              <option value={5}>5 Stars Only</option>
            </select>
          </div>
        </div>

      {/* Products and Sorting */}
      <div className="flex-1">
        <div className="flex justify-between items-center mb-4">
          <p>{filteredProducts.length} products found</p>
          <select 
            className="p-2 border rounded"
            onChange={handleSortChange}
            value={sortOption}
          >
            <option value="default">Default Sorting</option>
            <option value="price-low-high">Price: Low to High</option>
            <option value="price-high-low">Price: High to Low</option>
            <option value="name-a-z">Name: A to Z</option>
            <option value="name-z-a">Name: Z to A</option>
          </select>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredProducts.map((product) => (
            <ProductCardWithHover key={product.id} product={product} />
          ))}
        </div>
      </div>
    </div>
    </div>
    );
    };

export default AllProductsPage;
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import products from '../data/products';
import { ChevronRightIcon } from '@heroicons/react/solid';
import ProductCardWithHover from '../components/ProductCardWithHover';

const BrandPage = () => {
  const { brand } = useParams();
  const navigate = useNavigate();
  const [brandProducts, setBrandProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const normalizeBrand = (brandName) => {
    return brandName.toLowerCase().replace(/\s+/g, '').trim();
  };

  useEffect(() => {
    const fetchBrandProducts = async () => {
      setLoading(true);
      setError(null);
      try {
        
        const normalizedBrand = normalizeBrand(decodeURIComponent(brand));
        
        setBrandProducts(products.filter((product) => 
          product.brand && normalizeBrand(product.brand) === normalizedBrand
        ));
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('An error occurred while fetching products.');
      } finally {
        setLoading(false);
      }
    }

    if (!brand) {
      console.error('Brand name is undefined');
      navigate('/');
      return;
    }
    
    fetchBrandProducts();
  }, [brand, navigate]);

  const displayBrand = decodeURIComponent(brand)
    .trim()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  if (!brand) {
    return null; // Render nothing while redirecting
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-4">
        <Link to="/" className="text-gray-600 hover:text-gray-800">
          Home
        </Link>
        <ChevronRightIcon className="h-5 w-5 mx-2 text-gray-400" />
        <span className="text-gray-800 font-semibold">{displayBrand}</span>
      </div>

      <h1 className="text-3xl font-bold mb-6">{displayBrand} Products</h1>

      {brandProducts.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {brandProducts.map((product) => (
            <ProductCardWithHover key={product.id} product={product} />
          ))}
        </div>
      ) : (
        <p>No products found for this brand.</p>
      )}
    </div>
  );
};

export default BrandPage;
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-8 bg-gray-50 text-gray-900">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
        <p className="mb-4">
          Welcome to SHAMS AL FANAA GENERAL TRADING L.L.C. SHAMS AL FANAA GENERAL
          TRADING L.L.C ("us", "we", or "our") operates SHAMS AL FANAA GENERAL
          TRADING L.L.C (hereinafter referred to as "Service").
        </p>
        <p className="mb-4">
          Our Privacy Policy governs your visit to SHAMS AL FANAA GENERAL TRADING
          L.L.C and explains how we collect, safeguard and disclose information
          that results from your use of our Service.
        </p>
        <p className="mb-4">
          We use your data to provide and improve Service. By using Service, you
          agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms used
          in this Privacy Policy have the same meanings as in our Terms and
          Conditions.
        </p>
        <p>
          Our Terms and Conditions ("Terms") govern all use of our Service and
          together with the Privacy Policy constitutes your agreement with us
          ("agreement").
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Definitions</h2>
        <ul className="list-disc pl-5">
          <li className="mb-2">
            <strong>SERVICE:</strong> The alnooristore.com and website operated
            by SHAMS AL FANAA GENERAL TRADING L.L.C
          </li>
          <li className="mb-2">
            <strong>PERSONAL DATA:</strong> Data about a living individual who
            can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession).
          </li>
          <li className="mb-2">
            <strong>USAGE DATA:</strong> Data collected automatically either
            generated by the use of Service or from Service infrastructure itself
            (e.g., duration of a page visit).
          </li>
          <li className="mb-2">
            <strong>COOKIES:</strong> Small files stored on your device
            (computer or mobile device).
          </li>
          <li className="mb-2">
            <strong>DATA CONTROLLER:</strong> A natural or legal person who
            determines the purposes for which and the manner in which any
            personal data are, or are to be, processed. For this Privacy Policy,
            we are the Data Controller of your data.
          </li>
          <li className="mb-2">
            <strong>DATA PROCESSORS (OR SERVICE PROVIDERS):</strong> Any natural
            or legal person who processes the data on behalf of the Data
            Controller. We may use the services of various Service Providers in
            order to process your data more effectively.
          </li>
          <li className="mb-2">
            <strong>DATA SUBJECT:</strong> Any living individual who is the
            subject of Personal Data.
          </li>
          <li>
            <strong>THE USER:</strong> The individual using our Service. The User
            corresponds to the Data Subject, who is the subject of Personal Data.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Information Collection and Use</h2>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Types of Data Collected</h2>
        <h3 className="text-xl font-semibold mb-2">Personal Data</h3>
        <p className="mb-4">
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally identifiable information may
          include, but is not limited to:
        </p>
        <ul className="list-disc pl-5">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>
            Address, Country, State, Province, ZIP/Postal code, City
          </li>
          <li>Cookies and Usage Data</li>
        </ul>
        <p className="mt-4">
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be of
          interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">Usage Data</h3>
        <p>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access Service by or through any device
          (“Usage Data”).
        </p>
        <p>
          This Usage Data may include information such as your computer’s
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          When you access Service with a device, this Usage Data may include
          information such as the type of device you use, your device unique ID,
          the IP address of your device, your device operating system, the type
          of Internet browser you use, unique device identifiers and other
          diagnostic data.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">Location Data</h3>
        <p>
          We may use and store information about your location if you give us
          permission to do so (“Location Data”). We use this data to provide
          features of our Service, to improve and customize our Service.
        </p>
        <p>
          You can enable or disable location services when you use our Service at
          any time by way of your device settings.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Cookie Policy</h2>
        <h3 className="text-xl font-semibold mb-2">Tracking Cookies Data</h3>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
        </p>
        <p>
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are also
          used such as beacons, tags and scripts to collect and track information
          and to improve and analyze our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate when
          a cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Service.
        </p>
        <p>Examples of Cookies we use:</p>
        <ul className="list-disc pl-5">
          <li>Session Cookies: We use Session Cookies to operate our Service.</li>
          <li>Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</li>
          <li>Security Cookies: We use Security Cookies for security purposes.</li>
          <li>Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Other Data</h2>
        <p>
          While using our Service, we may also collect the following information:
          sex, age, date of birth, place of birth, passport details, citizenship,
          registration at place of residence and actual address, telephone number
          (work, mobile), details of documents on education, qualification,
          professional training, employment agreements, non-disclosure
          agreements, information on bonuses and compensation, information on
          marital status, family members, social security (or other taxpayer
          identification) number, office location and other data.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Use of Data</h2>
        <p>
          SHAMS AL FANAA GENERAL TRADING L.L.C uses the collected data for various
          purposes:
        </p>
        <ul className="list-disc pl-5">
          <li>to provide and maintain our Service;</li>
          <li>to notify you about changes to our Service;</li>
          <li>to allow you to participate in interactive features of our Service when you choose to do so;</li>
          <li>to provide customer support;</li>
          <li>to gather analysis or valuable information so that we can improve our Service;</li>
          <li>to monitor the usage of our Service;</li>
          <li>to detect, prevent and address technical issues;</li>
          <li>to fulfill any other purpose for which you provide it;</li>
          <li>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
          <li>to provide you with notices about your account and/or subscription, including expiration and renewal notices, email instructions, etc.;</li>
          <li>to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</li>
          <li>in any other way we may describe when you provide the information;</li>
          <li>for any other purpose with your consent.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Retention of Data</h2>
        <p>
          We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
        </p>
        <p>
          We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us by email: <a href="mailto:contactus@alnooristore.com" className="text-blue-600 underline">contactus@alnooristore.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';

const Warranty = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Warranty Information</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Our Warranty Policy</h2>
        <p className="mb-4">We stand behind the quality of our products and offer the following warranty coverage:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Electronics: 1-year limited warranty</li>
          <li>Furniture: 2-year limited warranty against manufacturing defects</li>
          <li>Clothing and accessories: 30-day warranty for manufacturing defects</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">What's Covered</h2>
        <p className="mb-4">Our warranty covers defects in materials and workmanship under normal use and maintenance. This includes:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Structural integrity issues</li>
          <li>Electrical or mechanical failures (for applicable products)</li>
          <li>Significant color fading or material deterioration beyond normal wear</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">What's Not Covered</h2>
        <p className="mb-4">The warranty does not cover damage caused by:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Accidents, misuse, or abuse</li>
          <li>Improper maintenance or storage</li>
          <li>Normal wear and tear</li>
          <li>Modifications or repairs not performed by authorized personnel</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">How to Claim Warranty</h2>
        <p className="mb-4">If you believe your product is eligible for warranty coverage:</p>
        <ol className="list-decimal list-inside mb-4">
          <li>Contact our customer service team with your proof of purchase</li>
          <li>Provide a detailed description of the issue and, if possible, photos</li>
          <li>We'll assess your claim and provide further instructions</li>
        </ol>
        <p>Please note that warranty service is only available in the United Arab Emirates.</p>
      </section>
    </div>
  );
};

export default Warranty;
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AppProvider } from './context/AppContext'; // Import the AppProvider
import { withGlobalErrorHandler } from './utils/apiUtils';
import ScrollToTop from './components/ScrollToTop';
import CategoryProductsPage from './pages/CategoryProductsPage';
import CartPage from "./pages/CartPage";
import Header from "./components/Header";
import Legal from './pages/Legal';
import Footer from "./components/Footer";
import Contact from './pages/Contact';
import Home from "./pages/Home";
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProductDetails from "./pages/ProductDetails";
import AboutUs from "./pages/AboutUs";
import CustomHeroSlider from './components/CustomHeroSlider';
import AllProductsPage from './pages/AllProductsPage';
import Faq from './pages/Faq';
import Warranty from './pages/Warranty';
import ShippingReturns from './pages/ShippingReturns';
import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import Checkout from './pages/CheckoutPage';
import Checkout1 from './pages/Checkout';
import Dashboard from './pages/Dashboard';
import OrderCompletePage from './pages/OrderCompletePage';
import AllOrders from './pages/AllOrders';
import BrandPage from './pages/BrandPage';

const AppContent = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="flex flex-col min-h-screen">
      <ScrollToTop />
      <Header />
      {isHomePage && (
        <div className="w-full">
          <CustomHeroSlider />
        </div>
      )}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<AllProductsPage />} />
          <Route path="/product/:slug" element={<ProductDetails />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/category/:category" element={<CategoryProductsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/support" element={<Contact/>} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/warranty" element={<Warranty />} />
          <Route path="/shipping" element={<ShippingReturns />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/checkout" element={<Checkout1 />} />
          <Route path='/account' element={<Dashboard />} />
          <Route path="/order-complete" element={<OrderCompletePage />} />
          <Route path="/orders" element={<AllOrders />} />
          <Route path="/brands/:brand" element={<BrandPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

const AppContentWithErrorHandler = withGlobalErrorHandler(AppContent);

const App = () => {
  return (
    <AppProvider>
      <Router>
        <AppContentWithErrorHandler />
      </Router>
    </AppProvider>
  );
};

export default App;
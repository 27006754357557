const products = [
    {
      id: 1,
      name: "Canon EOS 90D DSLR Camera",
      slug: "canon-eos-90d",
      brand: "Canon",
      price: 5499,
      currency: "AED",
      image: ["/assets/canon-eos-90d/1.png", "/assets/canon-eos-90d/2.png", "/assets/canon-eos-90d/3.png"],
      images: ["/assets/canon-eos-90d/1.png", "/assets/canon-eos-90d/2.png", "/assets/canon-eos-90d/3.png"],
      category: "Camera",
      description: "The Canon EOS 90D is a versatile DSLR camera that delivers high-quality images and videos. It's perfect for both enthusiasts and professionals looking for a reliable and feature-rich camera.",
      specifications: [
        "32.5 Megapixel APS-C CMOS Sensor",
        "DIGIC 8 Image Processor",
        "UHD 4K30p & Full HD 120p Video Recording",
        "45-Point All Cross-Type AF System",
        "Dual Pixel CMOS AF with 5481 AF Points",
        "220,000-Pixel AE Metering Sensor",
        '3" 1.04m-Dot Vari-Angle Touchscreen LCD',
        "10 fps Shooting, ISO 100-25600",
        "Built-In Wi-Fi and Bluetooth",
        "EOS iTR AF, Electronic Shutter Function"
      ],
      reviews: [
        { id: 1, author: "John D.", rating: 5, comment: "Excellent camera, highly recommended!" },
        { id: 2, author: "Sarah M.", rating: 4, comment: "Great value for money, but the learning curve is steep." },
        { id: 3, author: "Mike R.", rating: 5, comment: "Professional quality images, couldn't be happier!" },
      ]
    },
    {
      id: 2,
      name: "Sony Alpha a7 III",
      slug: "sony-alpha-a7-iii",
      brand: "Sony",
      price: 7899,
      currency: "AED",
      image: ["/assets/sony-alpha-a7-iii/1.webp", "/assets/sony-alpha-a7-iii/2.webp", "/assets/sony-alpha-a7-iii/3.webp"],
      images: ["/assets/sony-alpha-a7-iii/1.webp", "/assets/sony-alpha-a7-iii/2.webp", "/assets/sony-alpha-a7-iii/3.webp"],
      category: "Camera",
      description: "The Sony Alpha a7 III is a full-frame mirrorless camera that offers exceptional image quality, fast performance, and advanced features in a compact body.",
      specifications: [
        "24.2MP Full-Frame Exmor R BSI CMOS Sensor",
        "BIONZ X Image Processor & Front-End LSI",
        "693-Point Hybrid AF System",
        "UHD 4K30p Video with HLG & S-Log3 Gammas",
        "2.36m-Dot Tru-Finder OLED EVF",
        "3.0'' 922k-Dot Tilting Touchscreen LCD",
        "5-Axis SteadyShot INSIDE Stabilization",
        "ISO 100-51200, Extended ISO 50-204800",
        "10 fps Shooting with AF/AE Tracking",
        "Built-In Wi-Fi and NFC, Dual SD Slots"
      ],
      reviews: [
        { id: 1, author: "Emily L.", rating: 5, comment: "Amazing low-light performance and autofocus!" },
        { id: 2, author: "David K.", rating: 4, comment: "Versatile camera, but menu system can be complex." },
        { id: 3, author: "Lisa T.", rating: 5, comment: "Perfect for both photography and videography." },
      ]
    },
    {
      id: 3,
      name: "DJI Ronin-S Gimbal",
      slug: "dji-ronin",
      brand: "dji",
      price: 2799,
      currency: "AED",
      image: ["/assets/dji-ronin/1.webp", "/assets/dji-ronin/2.webp", "/assets/dji-ronin/3.webp"],
      images: ["/assets/dji-ronin/1.webp", "/assets/dji-ronin/2.webp", "/assets/dji-ronin/3.webp"],
      category: "Gimbal",
      description: "The DJI Ronin-S is a versatile 3-axis stabilizer for DSLR and mirrorless cameras, offering smooth and stable footage for professional videographers and filmmakers.",
      specifications: [
        "3-Axis Stabilization",
        "8.8 lb Payload",
        "One-Handed Operation",
        "Mobile Device Holder",
        "Sport Mode for Fast Movements",
        "360° Roll",
        "Focus Wheel",
        "Customizable Parameter Groups",
        "Automated Smart Shooting Modes",
        "Supports Camera Control"
      ],
      reviews: [
        { id: 1, author: "Alex M.", rating: 5, comment: "Incredible stabilization, transformed my videography!" },
        { id: 2, author: "Sophia R.", rating: 4, comment: "Bit heavy for long shoots, but results are worth it." },
        { id: 3, author: "Chris B.", rating: 5, comment: "Professional-grade gimbal at a reasonable price." },
      ]
    },
    {
        id: 4,
        name: "Nikon Z6 II Mirrorless Camera",
        slug: "nikon-z6-ii",
        brand: "nikon",
        price: 7499,
        currency: "AED",
        images: ["/assets/nikon-z6-ii/1.jpg", "/assets/nikon-z6-ii/2.webp", "/assets/nikon-z6-ii/3.webp", "/assets/nikon-z6-ii/4.webp"],
        category: "Camera",
        description: "The Nikon Z6 II is a versatile full-frame mirrorless camera that offers excellent image quality, fast performance, and robust video capabilities.",
        specifications: [
          "24.5MP FX-Format BSI CMOS Sensor",
          "Dual EXPEED 6 Image Processors",
          "UHD 4K60 Video; N-Log & 10-Bit HDMI Out",
          "273-Point Phase-Detect AF System",
          "14 fps Cont. Shooting, ISO 100-51200",
          "5-Axis In-Body Vibration Reduction",
          "3.6m-Dot OLED Electronic Viewfinder",
          "3.2'' 2.1m-Dot Tilting Touchscreen LCD",
          "Dual Memory Card Slots (CFexpress/XQD & SD)",
          "Built-In Wi-Fi and Bluetooth"
        ],
        reviews: [
          { id: 1, author: "Mark S.", rating: 5, comment: "Exceptional low-light performance and image quality!" },
          { id: 2, author: "Rachel T.", rating: 4, comment: "Great camera, but the menu system takes some getting used to." },
        ]
      },
      {
        id: 5,
        name: "DJI Mavic Air 2 Drone",
        slug: "dji-mavic-air-2",
        brand: "dji",
        price: 3299,
        currency: "AED",
        images: ["/assets/mavic-air-2/1.png", "/assets/mavic-air-2/2.webp", "/assets/mavic-air-2/3.jpeg", "/assets/mavic-air-2/4.webp"],
        category: "Drone",
        description: "The DJI Mavic Air 2 is a powerful and portable drone that offers high-quality 4K video, intelligent shooting modes, and extended flight time.",
        specifications: [
          "1/2'' CMOS Sensor, 48MP Photos",
          "Up to 4K60 Video & 240 fps 1080p Slow Motion",
          "3-Axis Gimbal with 4K Camera",
          "Up to 34 Minutes of Flight Time",
          "OcuSync 2.0 10km HD Transmission",
          "FocusTrack Subject Tracking Modes",
          "Obstacle Sensing in 3 Directions",
          "HDR Video & 8K Hyperlapse",
          "APAS 3.0 Advanced Pilot Assistance",
          "ActiveTrack 3.0 & Point of Interest 3.0"
        ],
        reviews: [
          { id: 1, author: "Tom H.", rating: 5, comment: "Amazing drone for both beginners and pros!" },
          { id: 2, author: "Emma W.", rating: 4, comment: "Great features, but be mindful of local drone regulations." },
        ]
      },
      {
        id: 6,
        name: "Godox AD200Pro Pocket Flash",
        slug: "godox-ad200pro",
        brand: "godox",
        price: 1199,
        currency: "AED",
        images: ["/assets/godox-ad200/1.jpg", "/assets/godox-ad200/2.png", "/assets/godox-ad200/3.webp", "/assets/godox-ad200/4.webp"],
        category: "Lighting",
        description: "The Godox AD200Pro is a versatile and portable flash unit that offers high power output in a compact form factor.",
        specifications: [
          "200Ws Output",
          "Interchangeable Flash Head System",
          "1/8000 Sec Flash Duration",
          "0.01-1.8 Sec Recycle Time",
          "2900mAh Lithium Battery",
          "TTL / Manual / Multi Flash Modes",
          "Built-in 2.4G Wireless X System",
          "Stable Color Temperature Mode",
          "12 Custom Functions",
          "Large LCD Display"
        ],
        reviews: [
          { id: 1, author: "Sarah L.", rating: 5, comment: "Powerful flash in a compact package. Love it!" },
          { id: 2, author: "Mike R.", rating: 4, comment: "Great for on-location shoots, but can be complex for beginners." },
        ]
      },
      {
        id: 7,
        name: "Manfrotto MT055XPRO3 Tripod",
        slug: "manfrotto-mt055xpro3",
        brand: "manfrotto",
        price: 899,
        currency: "AED",
        images: ["/assets/manfrotto-055/1.jpg", "/assets/manfrotto-055/2.jpg", "/assets/manfrotto-055/3.webp"],
        category: "Tripod",
        description: "The Manfrotto MT055XPRO3 is a versatile and sturdy tripod designed for professional photographers and videographers.",
        specifications: [
          "Aluminum Construction",
          "Maximum Height: 170 cm",
          "Minimum Height: 9 cm",
          "Folded Length: 63 cm",
          "Maximum Load Capacity: 9 kg",
          "Quick Power Lock System",
          "90° Column for Macro Shooting",
          "Easy Link Connection for Accessories",
          "Bubble Level",
          "3 Leg Angle Positions"
        ],
        reviews: [
          { id: 1, author: "Chris B.", rating: 5, comment: "Rock-solid stability. Perfect for long exposures!" },
          { id: 2, author: "Lisa M.", rating: 4, comment: "Bit heavy for travel, but unbeatable performance." },
        ]
      },
      {
        id: 8,
        name: "Rode VideoMic Pro+ Shotgun Microphone",
        slug: "rode-videomic-pro-shotgun-microphone",
        brand: "rode",
        price: 699,
        currency: "AED",
        images: ["/assets/rode-videomic/1.jpg", "/assets/rode-videomic/2.jpeg", "/assets/rode-videomic/3.webp"],
        category: "Audio",
        description: "The Rode VideoMic Pro+ is a high-quality on-camera shotgun microphone designed for professional video production.",
        specifications: [
          "Supercardioid Polar Pattern",
          "Rycote Lyre Shock Mount",
          "Digital Switching",
          "2-Stage High Pass Filter",
          "3-Stage Gain Control",
          "Safety Channel",
          "Automatic Power Function",
          "USB-C Connectivity",
          "Rechargeable Battery",
          "3.5mm TRS Output"
        ],
        reviews: [
          { id: 1, author: "David W.", rating: 3, comment: "Significantly improved my video audio quality!" },
          { id: 2, author: "Anna K.", rating: 3, comment: "Great mic, but a bit pricey for hobbyists." },
        ]
      },
      {
        id: 9,
        name: "Profoto B10 Plus AirTTL Studio Light",
        slug: "profoto-b10-plus",
        brand: "profoto",
        price: 3999,
        currency: "AED",
        images: ["/assets/profoto-b10/1.png", "/assets/profoto-b10/2.jpeg", "/assets/profoto-b10/3.jpeg"],
        category: "Lighting",
        description: "The Profoto B10 Plus is a powerful and portable studio light that offers both flash and continuous light capabilities.",
        specifications: [
          "500Ws Flash Output",
          "2500 Lumens Continuous Light",
          "HSS, TTL, and AirTTL Compatible",
          "0.05-2.0 Sec Recycle Time",
          "1.5 Sec Flash Duration",
          "Adjustable Color Temperature: 3000-6500K",
          "Bluetooth Connectivity",
          "Removable Lithium-Ion Battery",
          "Compact and Lightweight Design",
          "Compatible with Profoto Light Shaping Tools"
        ],
        reviews: [
          { id: 1, author: "Emily R.", rating: 2, comment: "Studio-quality lighting in a portable package!" },
          { id: 2, author: "James T.", rating: 3, comment: "Excellent light, but requires a significant investment." },
        ]
      },
      {
        id: 10,
        name: "DJI RSC 2 Gimbal Stabilizer",
        slug: "dji-rsc2-gimbal-stabilizer",
        brand: "dji",
        price: 1799,
        currency: "AED",
        images: ["/assets/dji-rsc2/1.jpg", "/assets/dji-rsc2/2.jpg", "/assets/dji-rsc2/3.png"],
        category: "Gimbal",
        description: "The DJI RSC 2 is a compact and powerful 3-axis gimbal stabilizer designed for mirrorless and DSLR cameras.",
        specifications: [
          "3-Axis Stabilization",
          "6.6 lb Payload",
          "Foldable Design",
          "1'' OLED Screen",
          "SuperSmooth Mode",
          "Time Tunnel Mode",
          "360° Roll",
          "12-Hour Battery Life",
          "Automated Axis Locks",
          "Supports Camera Control"
        ],
        reviews: [
          { id: 1, author: "Sophie L.", rating: 2, comment: "Incredibly smooth footage. A game-changer for my videos!" },
          { id: 2, author: "Mark D.", rating: 3, comment: "Great stabilization, but the learning curve is steep." },
        ]
      },
      {
        id: 11,
        name: "Atomos Ninja V 5\" 4K HDMI Recording Monitor",
        slug: "atomos-ninja-v-5-4k-hdmi-recording-monitor",
        brand: "atomos",
        price: 2499,
        currency: "AED",
        images: ["/assets/atomos-ninja/1.webp", "/assets/atomos-ninja/2.jpg", "/assets/atomos-ninja/3.jpeg"],
        category: "Monitor",
        description: "The Atomos Ninja V is a 5\" 4K HDMI recording monitor that offers professional-grade monitoring and recording capabilities.",
        specifications: [
          "5\" 1920x1080 Touchscreen Display",
          "4K HDMI Input",
          "Records 4K60p Video",
          "10-Bit 4:2:2 ProRes & DNx Recording",
          "HDR Monitoring",
          "1000 cd/m² Brightness",
          "AtomHDR Technology",
          "3D LUTs",
          "Waveform & Vectorscope Monitoring",
          "Sony NP-F Battery Compatible"
        ],
        reviews: [
          { id: 1, author: "Alex K.", rating: 3, comment: "Excellent monitor for both on-set and in-studio use!" },
          { id: 2, author: "Olivia M.", rating: 3.5, comment: "Great features, but the battery life could be better." },
        ]
      },
      {
        id: 12,
        name: "Profoto B10 Plus AirTTL Studio Light",
        slug: "profoto-b10-plus-airttl-studio-light",
        brand: "profoto",
        price: 8999,
        currency: "AED",
        images: ["/assets/profoto-b10x-plus/1.jpeg", "/assets/profoto-b10x-plus/2.jpg", "/assets/profoto-b10x-plus/3.jpeg"],
        category: "Lighting",
        description: "The Profoto B10 Plus is a powerful and portable studio light that offers both continuous and flash lighting options for professional photographers.",
        specifications: [
          "250Ws Flash Output",
          "2500 Lumens Continuous Light",
          "TTL and HSS Support",
          "0.05-2.0 Sec Recycle Time",
          "1.9 Sec Flash Duration",
          "Adjustable Color Temperature: 3000-6500K",
          "Built-in Bluetooth for Profoto App Control",
          "AirTTL Remote Compatible",
          "Removable Lithium-Ion Battery",
          "USB-C Port for Firmware Updates"
        ],
        reviews: [
          { id: 1, author: "Alex P.", rating: 5, comment: "Incredible versatility and power in a compact package!" },
          { id: 2, author: "Samantha R.", rating: 4, comment: "Excellent light, but the price point is high for beginners." }
        ]
      },
      {
        id: 13,
        name: "DJI RS 2 Gimbal Stabilizer",
        slug: "dji-rs2-gimbal-stabilizer",
        brand: "dji",
        price: 3499,
        currency: "AED",
        images: ["/assets/dji-rs2/1.webp", "/assets/dji-rs2/2.jpg", "/assets/dji-rs2/3.jpeg"],
        category: "Gimbal",
        description: "The DJI RS 2 is a professional 3-axis gimbal stabilizer designed for DSLR and mirrorless cameras, offering advanced stabilization and intelligent features.",
        specifications: [
          "Supports up to 10 lb Camera Payload",
          "SuperSmooth Mode for 100mm Focal Length",
          "Dual-Layer Camera Mounting Plate",
          "1.4\" Full-Color Touchscreen",
          "AutoTune Feature",
          "Axis Locks on All Three Axes",
          "Foldable Design",
          "USB-C Port for Camera Control",
          "Bluetooth 5.0 and 2.4 GHz Wireless",
          "Companion iOS/Android App"
        ],
        reviews: [
          { id: 1, author: "Michael T.", rating: 5, comment: "The best gimbal I've ever used. Smooth and intuitive." },
          { id: 2, author: "Emma S.", rating: 4, comment: "Great stabilization, but there's a learning curve to master all features." }
        ]
      },
      {
        id: 14,
        name: "Atomos Ninja V 5\" 4K HDMI Recording Monitor",
        slug: "atomos-ninja-v-5-4k-hdmi-recording-monitor",
        brand: "atomos",
        price: 2599,
        currency: "AED",
        images: ["/assets/atomos-ninja-v/1.jpeg", "/assets/atomos-ninja-v/2.jpeg", "/assets/atomos-ninja-v/3.jpeg"],
        category: "Video Accessories",
        description: "The Atomos Ninja V is a 5-inch on-camera recording monitor that supports 4K video input and recording, ideal for filmmakers and video professionals.",
        specifications: [
          "5\" 1920x1080 Touchscreen Display",
          "Records 4K60p Video",
          "10-Bit 4:2:2 ProRes and DNxHR Recording",
          "HDR Monitoring with AtomHDR Technology",
          "1500 cd/m² Brightness",
          "HDMI 2.0 Input",
          "Records to 2.5\" SATA SSD/HDD",
          "Sony NP-F Battery Series Compatible",
          "3D LUTs and Custom LUTs Support",
          "Compact and Lightweight Design"
        ],
        reviews: [
          { id: 1, author: "David L.", rating: 5, comment: "Game-changer for my video production workflow!" },
          { id: 2, author: "Sarah K.", rating: 4, comment: "Excellent monitor, but be prepared for additional costs for SSDs." }
        ]
      },
      {
        id: 15,
        name: "Sigma 24-70mm f/2.8 DG DN Art Lens",
        slug: "sigma-24-70-dg-dn-art-lens",
        brand: "sigma",
        price: 3799,
        currency: "AED",
        images: ["/assets/sigma-24-70/1.jpg", "/assets/sigma-24-70/2.webp", "/assets/sigma-24-70/3.webp"],
        category: "Lenses",
        description: "The Sigma 24-70mm f/2.8 DG DN Art is a versatile zoom lens designed for full-frame mirrorless cameras, offering excellent image quality and a constant f/2.8 aperture.",
        specifications: [
          "Designed for Full-Frame Mirrorless Cameras",
          "Aperture Range: f/2.8 to f/22",
          "Low Dispersion and Aspherical Elements",
          "Super Multi-Layer Coating",
          "Hypersonic AF Motor",
          "Weather-Sealed Construction",
          "Rounded 11-Blade Diaphragm",
          "Zoom Lock Switch",
          "82mm Filter Thread",
          "Compatible with Lens Aberration Correction"
        ],
        reviews: [
          { id: 1, author: "Mark R.", rating: 5, comment: "Incredible sharpness and versatility. A must-have lens!" },
          { id: 2, author: "Jessica T.", rating: 4, comment: "Great quality, but it's a bit heavy for all-day use." }
        ]
      },
      {
        id: 16,
        name: "Zhiyun Weebill 2 Gimbal Stabilizer",
        slug: "zhiyun-weebill-2-gimbal-stabilizer",
        brand: "zhiyun",
        price: 1999,
        currency: "AED",
        images: ["/assets/zhiyun-weebill-2/1.jpg", "/assets/zhiyun-weebill-2/2.webp", "/assets/zhiyun-weebill-2/3.webp"],
        category: "Gimbal",
        description: "The Zhiyun Weebill 2 is a compact and innovative 3-axis gimbal stabilizer for mirrorless and DSLR cameras, featuring a flip-out touchscreen for easy control.",
        specifications: [
          "Supports Cameras up to 7.3 lb",
          "2.88\" Flip-Out Touchscreen",
          "Infineon Sensor System",
          "9 Built-In Camera Parameters",
          "TransMount Video Transmission System",
          "Dual-Mode Follow Focus Control",
          "Intelligent Shooting Modes",
          "Foldable Design for Easy Transport",
          "Up to 9 Hours Runtime",
          "USB-C and Wi-Fi Connectivity"
        ],
        reviews: [
          { id: 1, author: "Ryan M.", rating: 5, comment: "The touchscreen is a game-changer. Love this gimbal!" },
          { id: 2, author: "Laura B.", rating: 4, comment: "Great stabilization, but the app could use some improvements." }
        ]
      },
      {
        id: 17,
        name: "Godox AD400Pro Witstro All-In-One Outdoor Flash",
        slug: "godox-ad400pro-witstro-all-in-one-outdoor-flash",
        brand: "godox",
        price: 2499,
        currency: "AED",
        images: ["/assets/godox-ad400pro/1.jpg", "/assets/godox-ad400pro/2.jpeg", "/assets/godox-ad400pro/3.jpeg"],
        category: "Lighting",
        description: "The Godox AD400Pro is a powerful and versatile outdoor flash unit, perfect for on-location photography with its portability and high-speed sync capabilities.",
        specifications: [
          "400Ws Output",
          "9-Stop Power Range",
          "0.01-1 Sec Recycle Time",
          "1/8000 Sec High-Speed Sync",
          "2.4 GHz X Wireless System",
          "Bowens Mount Adapter",
          "30W Modeling Lamp",
          "Stable Color Temperature Mode",
          "Large LCD Screen",
          "Battery-Powered (Up to 390 Full Power Flashes)"
        ],
        reviews: [
          { id: 1, author: "Chris P.", rating: 5, comment: "Incredible power and versatility. A game-changer for outdoor shoots!" },
          { id: 2, author: "Megan S.", rating: 4, comment: "Great flash, but the battery life could be better for long shoots." }
        ]
      },
      {
        id: 18,
        name: "DJI Mavic 3 Pro Drone",
        slug: "dji-mavic-3-pro-drone",
        brand: "dji",
        price: 7999,
        currency: "AED",
        images: ["/assets/dji-mavic-3-pro/1.png", "/assets/dji-mavic-3-pro/2.webp", "/assets/dji-mavic-3-pro/3.jpeg"],
        category: "Drones",
        description: "The DJI Mavic 3 Pro is a high-end consumer drone featuring a Hasselblad camera, long flight time, and advanced obstacle sensing capabilities.",
        specifications: [
          "Hasselblad 4/3 CMOS 20MP Camera",
          "5.1K Video at 50fps",
          "46 Minutes Max Flight Time",
          "15km Max Transmission Range",
          "Omnidirectional Obstacle Sensing",
          "Advanced Return to Home (RTH)",
          "10-bit D-Log Color Profile",
          "8GB Internal Storage",
          "Foldable Design",
          "Compatible with DJI RC Pro Controller"
        ],
        reviews: [
          { id: 1, author: "Tom K.", rating: 5, comment: "The image quality is mind-blowing. Best drone I've ever owned!" },
          { id: 2, author: "Lisa M.", rating: 4, comment: "Fantastic drone, but it comes with a steep learning curve." }
        ]
      },
      {
        id: 19,
        name: "Blackmagic Pocket Cinema Camera 6K Pro",
        slug: "blackmagic-pocket-6k-pro",
        brand: "blackmagic",
         price: 9999,
        currency: "AED",
        images: ["/assets/blackmagic-pocket-6k-pro/1.jpeg", "/assets/blackmagic-pocket-6k-pro/2.jpg", "/assets/blackmagic-pocket-6k-pro/3.jpeg"],
        category: "Cameras",
        description: "The Blackmagic Pocket Cinema Camera 6K Pro is a professional-grade cinema camera that offers 6K resolution, 13 stops of dynamic range, and dual native ISO up to 25,600.",
        specifications: [
          "Super 35 Sensor",
          "6K Resolution at 50 fps",
          "13 Stops of Dynamic Range",
          "Dual Native ISO up to 25,600",
          "5\" 1500 nit Tilting Touchscreen",
          "Built-in ND Filters",
          "CFast 2.0 & SD/UHS-II Card Slots",
          "USB-C External Recording",
          "Mini XLR Audio Input & 3.5mm Jack",
          "Includes DaVinci Resolve Studio"
        ],
        reviews: [
          { id: 1, author: "James R.", rating: 5, comment: "Unbelievable image quality. This camera is a game-changer!" },
          { id: 2, author: "Emily W.", rating: 4, comment: "Fantastic for cinematic projects, but requires a learning curve." }
        ]
      },
      {
        id: 20,
        name: "Profoto A1X AirTTL-C Studio Light for Canon",
        slug: "profoto-a1x-airttl-c-studio-light-for-canon",
        brand: "profoto",
        price: 3499,
        currency: "AED",
        images: ["/assets/profoto-a1x/1.jpeg", "/assets/profoto-a1x/2.jpg", "/assets/profoto-a1x/3.jpeg"],
        category: "Lighting",
        description: "The Profoto A1X AirTTL-C is a powerful and versatile on-camera flash for Canon cameras, offering natural and beautiful light for professional photographers.",
        specifications: [
          "76Ws Output",
          "1.2 Second Recycle Time",
          "450 Full-Power Flashes per Charge",
          "High-Speed Sync (up to 1/8000s)",
          "TTL and Manual Mode",
          "20 Wireless Channels",
          "AirTTL Remote",
          "Magnetic Click-On Mount for Light Shaping Tools",
          "Modeling Light",
          "Large LCD Display"
        ],
        reviews: [
          { id: 1, author: "Sarah L.", rating: 5, comment: "The light quality is amazing. Worth every penny!" },
          { id: 2, author: "Mike T.", rating: 4, comment: "Great flash, but it's on the pricier side." }
        ]
      },
      {
        id: 21,
        name: "Sennheiser MKE 600 Shotgun Microphone",
        slug: "sennheiser-mke600-shotgun-microphone",
        brand: "sennheiser",
        price: 1299,
        currency: "AED",
        images: ["/assets/sennheiser-mke600/1.jpg", "/assets/sennheiser-mke600/2.jpg", "/assets/sennheiser-mke600/3.jpg"],
        category: "Audio",
        description: "The Sennheiser MKE 600 is a professional shotgun microphone designed for video camera users who want superior sound quality for their recordings.",
        specifications: [
          "Highly Directional Pickup Pattern",
          "Low Inherent Self-Noise",
          "Switchable Low-Cut Filter",
          "Phantom or Battery Powered",
          "Rugged All-Metal Housing",
          "Wind Attenuation",
          "Foam Windshield Included",
          "XLR Output",
          "Battery Life: 150 Hours",
          "Weight: 128g"
        ],
        reviews: [
          { id: 1, author: "David C.", rating: 5, comment: "Excellent audio quality. Perfect for my documentary work!" },
          { id: 2, author: "Anna P.", rating: 4, comment: "Great mic, but a bit long for some camera setups." }
        ]
      },
      {
        id: 22,
        name: "Aputure LS C300d II LED Light Kit",
        slug: "aputure-ls-c300d-ii-led-light-kit",
        brand: "aputure",
        price: 4999,
        currency: "AED",
        images: ["/assets/aputure-ls-c300d-ii/1.jpg", "/assets/aputure-ls-c300d-ii/2.jpg", "/assets/aputure-ls-c300d-ii/3.jpeg"],
        category: "Lighting",
        description: "The Aputure LS C300d II is a powerful and versatile LED light that offers daylight-balanced output and advanced features for professional video and photo shoots.",
        specifications: [
          "300W Output",
          "5500K Color Temperature",
          "CRI/TLCI 96+",
          "0-100% Dimming",
          "2.4 GHz Wireless Remote",
          "DMX512 Compatible",
          "Effects Mode (Lightning, Paparazzi, etc.)",
          "Bowens Mount Compatible",
          "Silent Fan Mode",
          "Dual Power Options: AC Adapter or V-Mount Battery"
        ],
        reviews: [
          { id: 1, author: "Rachel K.", rating: 5, comment: "Incredible output and build quality. A game-changer for my studio!" },
          { id: 2, author: "Alex M.", rating: 4, comment: "Fantastic light, but the controller could be more intuitive." }
        ]
      },
      {
        id: 23,
        name: "DJI RS 3 Pro Gimbal Stabilizer",
        slug: "dji-rs-3-pro-gimbal-stabilizer",
        brand: "dji",
        price: 3699,
        currency: "AED",
        images: ["/assets/dji-rs-3-pro/1.webp", "/assets/dji-rs-3-pro/2.jpeg", "/assets/dji-rs-3-pro/3.png"],
        category: "Gimbal",
        description: "The DJI RS 3 Pro is a professional 3-axis gimbal stabilizer designed for cinema cameras and DSLRs, offering advanced stabilization and intelligent shooting modes.",
        specifications: [
          "Supports Cameras up to 10 lb",
          "3rd-Gen RS Stabilization Algorithm",
          "1.8\" OLED Touchscreen",
          "Automated Axis Locks",
          "LiDAR Focus Motor",
          "Wireless Video Transmission",
          "SuperSmooth Mode",
          "AI-Powered Subject Tracking",
          "12-Hour Battery Life",
          "USB-C Charging and Power Bank Function"
        ],
        reviews: [
          { id: 1, author: "Sam T.", rating: 5, comment: "The best gimbal I've ever used. Smooth operation and great features!" },
          { id: 2, author: "Emma L.", rating: 4, comment: "Excellent stabilization, but the app could use some improvements." }
        ]
      },
      {
        id: 24,
        name: "Rode VideoMic Pro+ Shotgun Microphone",
        slug: "rode-videomic-pro-plus",
        brand: "rode",
        price: 899,
        currency: "AED",
        images: ["/assets/rode-videomic-pro-plus/1.jpg", "/assets/rode-videomic-pro-plus/2.jpeg", "/assets/rode-videomic-pro-plus/3.webp"],
        category: "Audio",
        description: "The Rode VideoMic Pro+ is a premium on-camera shotgun microphone that delivers broadcast-quality audio for videographers and content creators.",
        specifications: [
          "Supercardioid Polar Pattern",
          "Rycote Lyre Shock Mount",
          "Digital Switching",
          "Safety Channel",
          "High-Pass Filter (75Hz or 150Hz)",
          "High Frequency Boost (+6dB)",
          "Automatic Power Function",
          "3.5mm TRS Output",
          "USB-C Power and Charging",
          "Rechargeable Lithium-Ion Battery"
        ],
        reviews: [
          { id: 1, author: "Linda H.", rating: 5, comment: "Incredible audio quality. A must-have for any videographer!" },
          { id: 2, author: "Paul R.", rating: 4, comment: "Great mic, but a bit pricey compared to competitors." }
        ]
      },
      {
        id: 25,
        name: "Atomos Ninja V 5\" 4K HDMI Recording Monitor",
        slug: "atomos-ninja-v",
        brand: "atomos",
        price: 2499,
        currency: "AED",
        images: ["/assets/atomos-ninja-v/1.jpeg", "/assets/atomos-ninja-v/2.jpeg", "/assets/atomos-ninja-v/3.jpeg"],
        category: "Monitors",
        description: "The Atomos Ninja V is a 5-inch 4K HDMI recording monitor that offers professional monitoring and recording capabilities in a compact form factor.",
        specifications: [
          "5\" 1920x1080 Touchscreen LCD",
          "4K HDMI Input",
          "10-Bit 4:2:2 ProRes & DNx Recording",
          "HDR Monitoring (AtomHDR)",
          "1500 cd/m² Brightness",
          "3D LUTs Support",
          "Waveform, Vectorscope, RGB Parade",
          "Sony NP-F Battery Compatible",
          "Compact and Lightweight Design",
          "Supports CFast 2.0 and SSD Media"
        ],
        reviews: [
          { id: 1, author: "Mark S.", rating: 5, comment: "Amazing monitor and recorder. The HDR capabilities are fantastic!" },
          { id: 2, author: "Julia W.", rating: 4, comment: "Great product, but the fan can be a bit noisy in quiet environments." }
        ]
      },
      {
        id: 26,
        name: "Manfrotto MT055CXPRO4 Carbon Fiber Tripod",
        slug: "manfrotto-mt055cxpro4",
        brand: "manfrotto",
        price: 1799,
        currency: "AED",
        images: ["/assets/manfrotto-mt055cxpro4/1.jpg", "/assets/manfrotto-mt055cxpro4/2.jpeg", "/assets/manfrotto-mt055cxpro4/3.jpg"],
        category: "Tripods",
        description: "The Manfrotto MT055CXPRO4 is a professional-grade carbon fiber tripod that offers exceptional stability and versatility for photographers and videographers.",
        specifications: [
          "Carbon Fiber Construction",
          "Maximum Height: 170 cm",
          "Minimum Height: 9 cm",
          "Folded Length: 54 cm",
          "Maximum Load Capacity: 9 kg",
          "4-Section Legs",
          "Quick Power Lock System",
          "90° Center Column Mechanism",
          "Easy Link Connector for Accessories",
          "Weight: 2 kg"
        ],
        reviews: [
          { id: 1, author: "Thomas B.", rating: 5, comment: "Incredibly sturdy and lightweight. Worth every penny!" },
          { id: 2, author: "Sophie L.", rating: 4, comment: "Great tripod, but the price is a bit steep for casual users." }
        ]
      },
      {
        id: 27,
        name: "DJI Mavic 3 Pro Cine Premium Combo",
        slug: "dji-mavic-3-pro-cine-premium-combo",
        brand: "dji",
        price: 12999,
        currency: "AED",
        images: ["/assets/dji-mavic-3-pro/1.png", "/assets/dji-mavic-3-pro/2.webp", "/assets/dji-mavic-3-pro/3.png"],
        category: "Drones",
        description: "The DJI Mavic 3 Pro Cine Premium Combo is a professional-grade drone featuring a Hasselblad camera, long flight time, and advanced obstacle sensing capabilities.",
        specifications: [
          "Hasselblad Camera with 4/3 CMOS",
          "5.1K Video at 50fps",
          "4K Video at 120fps",
          "20MP Still Photos",
          "46 Minutes Max Flight Time",
          "15km Max Transmission Range",
          "Omnidirectional Obstacle Sensing",
          "Advanced Return to Home (RTH)",
          "1TB SSD",
          "DJI RC Pro Remote Controller"
        ],
        reviews: [
          { id: 1, author: "James K.", rating: 5, comment: "Unbelievable image quality and flight performance. A game-changer for aerial cinematography!" },
          { id: 2, author: "Elena R.", rating: 4, comment: "Amazing drone, but the learning curve is steep for beginners." }
        ]
      },
      {
        id: 28,
        name: "Canon RF 28-70mm f/2L USM Lens",
        slug: "canon-rf-28-70mm",
        brand: "canon",
        price: 9999,
        currency: "AED",
        images: ["/assets/canon-rf-28-70mm/1.jpg", "/assets/canon-rf-28-70mm/2.jpeg", "/assets/canon-rf-28-70mm/3.webp"],
        category: "Lenses",
        description: "The Canon RF 28-70mm f/2L USM is a professional-grade zoom lens for Canon's full-frame mirrorless cameras, offering exceptional image quality and versatility.",
        specifications: [
          "Focal Length: 28-70mm",
          "Maximum Aperture: f/2",
          "Lens Mount: Canon RF",
          "Lens Construction: 19 elements in 13 groups",
          "Minimum Focus Distance: 0.39m",
          "Filter Size: 95mm",
          "Image Stabilization: No",
          "Weather-Sealed Construction",
          "Fluorine Coating",
          "Weight: 1430g"
        ],
        reviews: [
          { id: 1, author: "Michael C.", rating: 5, comment: "The holy grail of zoom lenses. Incredible sharpness and bokeh!" },
          { id: 2, author: "Sarah T.", rating: 4, comment: "Superb lens, but it's quite heavy for extended use." }
        ]
      },
      {
        id: 29,
        name: "Blackmagic Design URSA Mini Pro 12K",
        slug: "blackmagic-ursa-mini-pro-12k",
        brand: "blackmagic",
        price: 39999,
        currency: "AED",
        images: ["/assets/blackmagic-ursa-mini-pro-12k/1.jpg", "/assets/blackmagic-ursa-mini-pro-12k/2.jpeg", "/assets/blackmagic-ursa-mini-pro-12k/3.jpg"],
        category: "Cameras",
        description: "The Blackmagic Design URSA Mini Pro 12K is a professional digital film camera that offers groundbreaking 12K resolution and advanced color science.",
        specifications: [
          "12288 x 6480 12K Super 35 Sensor",
          "14 Stops of Dynamic Range",
          "Up to 60 fps at 12K Full Sensor",
          "Up to 110 fps at 8K",
          "Dual CFast 2.0 & SD/UHS-II Card Slots",
          "USB-C 3.1 Gen 2 Recording to SSD",
          "User-Changeable PL Lens Mount",
          "2nd-Gen Blackmagic RAW Codec",
          "5\" 1080p Touchscreen LCD",
          "2x 3G-SDI Outputs & 12G-SDI Input"
        ],
        reviews: [
          { id: 1, author: "David L.", rating: 5, comment: "The image quality is mind-blowing. This camera is revolutionizing digital cinematography!" },
          { id: 2, author: "Amanda W.", rating: 4, comment: "Incredible camera, but be prepared for massive storage requirements." }
        ]
      },
      {
        id: 30,
        name: "Profoto B10X Plus AirTTL Monolight",
        slug: "profoto-b10x-plus-monolight",
        brand: "profoto",
        price: 7499,
        currency: "AED",
        images: ["/assets/profoto-b10x-plus/1.jpeg", "/assets/profoto-b10x-plus/2.jpg", "/assets/profoto-b10x-plus/3.jpeg"],
        category: "Lighting",
        description: "The Profoto B10X Plus AirTTL Monolight is a powerful and versatile studio light that combines the performance of a monolight with the portability of a speedlight.",
        specifications: [
          "500Ws Output",
          "10 f-stop Power Range",
          "0.05-2.2s Recycle Time",
          "HSS (up to 1/8000s)",
          "TTL and Manual Mode",
          "Continuous Light Mode (2500 lumens)",
          "Bluetooth and AirTTL Connectivity",
          "OLED Display",
          "Removable Lithium-Ion Battery",
          "Weight: 1.9kg"
        ],
        reviews: [
          { id: 1, author: "Lisa M.", rating: 5, comment: "Perfect balance of power and portability. A must-have for location shoots!" },
          { id: 2, author: "Robert K.", rating: 4, comment: "Excellent light, but the battery life could be better for long shooting days." }
        ]
      },
      {
        id: 31,
        name: "Adobe Creative Cloud Photography Plan (1 Year)",
        slug: "adobe-creative-cloud-photography-plan",
        brand: "adobe",
        price: 599,
        currency: "AED",
        images: ["/assets/adobe-creative-cloud/1.jpg", "/assets/adobe-creative-cloud/2.png", "/assets/adobe-creative-cloud/3.jpg"],
        category: "Software",
        description: "The Adobe Creative Cloud Photography Plan provides access to industry-leading photo editing software, including Photoshop and Lightroom, for one year.",
        specifications: [
          "Includes Photoshop CC",
          "Includes Lightroom CC",
          "Includes Lightroom Classic CC",
          "20GB Cloud Storage",
          "Adobe Portfolio",
          "Adobe Fonts",
          "Access to Latest Updates",
          "Multi-Device Compatibility",
          "Tutorial Access",
          "24/7 Tech Support"
        ],
        reviews: [
          { id: 1, author: "Emily S.", rating: 5, comment: "Essential software for any serious photographer. The cloud integration is fantastic!" },
          { id: 2, author: "Mark D.", rating: 4, comment: "Great suite of tools, but the subscription model can be costly over time." }
        ]
      },
      {
        id: 32,
        name: "Peak Design Travel Backpack Sage (45L)",
        slug: "peak-design-travel-backpack-sage",
        brand: "peak-design",
        price: 1246.88,
        currency: "AED",
        images: ["/assets/BTR-45-SG-1/1.webp", "/assets/BTR-45-SG-1/2.webp", "/assets/BTR-45-SG-1/3.webp"],
        category: "Bags",
        description: "The sage-colored Travel Backpack from Peak Design is a bag that can serve as the core of a versatile transportation system for photographers, multimedia creators, and dedicated travelers. Expansion zippers along its spacious main compartment allows this backpack to increase from 30L to 45L in volume, while inside, a zippered partition system forms an optional front and rear divider. To further enhance the ability to organize this bag’s contents, separately available Peak Design compartments that are specifically designed to store camera, video, audio, and digital media equipment, as well as apparel or small electronics can be arranged within the Travel Backpack to create a customized carrying solution that can be changed as needed.",
        specifications: [
          "Versatile, Multi-Configuration Backpack",
          "Spacious, Expandable from 30L to 45L",
          "Designed for Internal Compartment System",
          "Compartments for Gear, Clothes",
          "Rear, Front, and Side Access",
          "15″ Laptop and 10″ Tablet Pockets",
          "Hidden Side Pockets, Front Pocket",
          "Organizational Pockets",
          "2 Bottle Pockets/Tripod, Monopod Holders",
          "4 Exterior Handles, Weather Resistant"
        ],
        reviews: [
          { id: 1, author: "Emily S.", rating: 5, comment: "Essential software for any serious photographer. The cloud integration is fantastic!" },
          { id: 2, author: "Mark D.", rating: 4, comment: "Great suite of tools, but the subscription model can be costly over time." }
        ]
      },
      {
        id: 33,
        name: "Lowepro ProTactic BP 450 AW II Camera Backpack",
        slug: "lowepro-protactic-bp-450-aw-ii",
        brand: "lowepro",
        price: 899,
        currency: "AED",
        images: ["/assets/lowepro-protactic-450/1.jpg", "/assets/lowepro-protactic-450/2.webp", "/assets/lowepro-protactic-450/3.webp"],
        category: "Bags",
        description: "The Lowepro ProTactic BP 450 AW II is a versatile, high-performance camera backpack designed for professional photographers and videographers.",
        specifications: [
          "Fits 1-2 Pro DSLRs, one with up to 70-200mm f/2.8 lens attached",
          "Fits 8 lenses/speed lights",
          "15`` laptop compartment",
          "Four-point access",
          "ActivZone System technology for comfort",
          "SlipLock compatible",
          "Built-in All Weather AW Cover",
          "Modular accessory system",
          "Interior dimensions: 30 x 16 x 44 cm",
          "Exterior dimensions: 34.8 x 27 x 48.8 cm"
        ],
        reviews: [
          { id: 1, author: "Alex M.", rating: 5, comment: "Extremely versatile and comfortable. Perfect for my wildlife photography trips." },
          { id: 2, author: "Samantha R.", rating: 4, comment: "Great bag, but a bit heavy when fully loaded." }
        ]
      },
      {
        id: 34,
        name: "Think Tank Photo Airport Security V3.0 Carry On",
        slug: "think-tank-airport-security",
        brand: "think-tank",
        price: 1199,
        currency: "AED",
        images: ["/assets/think-tank-airport-security/1.jpg", "/assets/think-tank-airport-security/2.jpeg", "/assets/think-tank-airport-security/3.jpg"],
        category: "Bags",
        description: "The Think Tank Photo Airport Security V3.0 is a high-capacity rolling camera bag designed to meet carry-on requirements for most airlines.",
        specifications: [
          "Holds 2 gripped DSLRs with lenses attached plus 4–6 standard zoom lenses",
          "Fits 15`` laptop and 10`` tablet",
          "TSA-approved combination lock",
          "Robust handles on 3 sides",
          "Expandable front pocket",
          "Tripod mount system",
          "Interior dimensions: 33 x 13 x 46 cm",
          "Exterior dimensions: 35.6 x 22.9 x 53.3 cm",
          "Weight: 5.4 kg",
          "Meets most international and U.S. carry-on requirements"
        ],
        reviews: [
          { id: 1, author: "Michael P.", rating: 5, comment: "The ultimate travel companion for photographers. Sturdy, spacious, and secure." },
          { id: 2, author: "Laura K.", rating: 4, comment: "Excellent quality, but it's quite heavy even when empty." }
        ]
      },
      {
        id: 35,
        name: "Peak Design Everyday Messenger 13L V2",
        slug: "peak-design-everyday-messenger",
        brand: "peak-design",
        price: 699,
        currency: "AED",
        images: ["/assets/peak-design-messenger/1.jpg", "/assets/peak-design-messenger/2.jpeg", "/assets/peak-design-messenger/3.jpeg"],
        category: "Bags",
        description: "The Peak Design Everyday Messenger 13L V2 is a versatile and stylish messenger bag perfect for photographers, commuters, and travelers alike.",
        specifications: [
          "13L capacity, expandable to 16L",
          "Fits 13`` laptop and 11`` tablet",
          "Weatherproof 400D nylon canvas shell",
          "UltraZip external zippers",
          "Flexfold dividers for customizable organization",
          "MagLatch closure system",
          "Multiple slip pockets and zip pockets",
          "Luggage pass-through",
          "Dimensions: 38 x 27 x 12 cm",
          "Weight: 1.1 kg"
        ],
        reviews: [
          { id: 1, author: "Chris T.", rating: 5, comment: "Sleek, functional, and comfortable. My go-to bag for daily use and short trips." },
          { id: 2, author: "Emma S.", rating: 4, comment: "Love the design and versatility, but wish it had more padding for camera gear." }
        ]
      },
      {
        id: 36,
        name: "Manfrotto Pro Light Reloader Switch-55 Backpack/Roller",
        slug: "manfrotto-reloader-switch",
        brand: "manfrotto",
        price: 1049,
        currency: "AED",
        images: ["/assets/manfrotto-reloader-switch/1.jpg", "/assets/manfrotto-reloader-switch/2.jpg", "/assets/manfrotto-reloader-switch/3.jpg"],
        category: "Bags",
        description: "The Manfrotto Pro Light Reloader Switch-55 is a 2-in-1 camera bag that can be used as both a backpack and a roller, ideal for traveling photographers.",
        specifications: [
          "Converts from roller to backpack",
          "Fits 2-3 Pro DSLRs, 8-10 lenses",
          "Dedicated laptop compartment (15``)",
          "Tablet compartment (10``)",
          "CPS (Camera Protection System)",
          "External tripod connections",
          "Water-repellent exterior",
          "TSA-approved zipper lock",
          "Interior dimensions: 35 x 16 x 51 cm",
          "Exterior dimensions: 35.5 x 23 x 55 cm"
        ],
        reviews: [
          { id: 1, author: "David W.", rating: 5, comment: "The versatility of this bag is unmatched. Perfect for air travel and location shoots." },
          { id: 2, author: "Sophie L.", rating: 4, comment: "Great concept and execution, but it's a bit heavy as a backpack when fully loaded." }
        ]
      },
      {
        id: 37,
        name: "Shimoda Explore V2 35 Backpack",
        slug: "shimoda-explore-v2",
        brand: "shimoda",
        price: 849,
        currency: "AED",
        images: ["/assets/shimoda-explore-v2/1.jpg", "/assets/shimoda-explore-v2/2.avif", "/assets/shimoda-explore-v2/3.jpg"],
        category: "Bags",
        description: "The Shimoda Explore V2 35 is a rugged, adventure-ready camera backpack designed for outdoor and travel photographers.",
        specifications: [
          "35L capacity",
          "Rear panel access",
          "Adjustable torso height",
          "Fits mirrorless or DSLR setup with 4-5 lenses",
          "13`` laptop sleeve",
          "Expandable roll-top",
          "Multiple accessory attachment points",
          "Weather-resistant materials",
          "Includes rain cover",
          "Dimensions: 29 x 51 x 28 cm"
        ],
        reviews: [
          { id: 1, author: "Ryan M.", rating: 5, comment: "The most comfortable camera backpack I've ever used. Perfect for long hikes and outdoor shoots." },
          { id: 2, author: "Olivia P.", rating: 4, comment: "Love the design and durability, but wish it had more small pockets for accessories." }
        ]
      },
      {
        id: 38,
        name: "SanDisk 128GB Extreme PRO SDXC UHS-I Card",
        slug: "sandisk-extreme-pro-128gb",
        brand: "sandisk",
        price: 199,
        currency: "AED",
        images: ["/assets/sandisk-extreme-pro-128gb/1.jpeg", "/assets/sandisk-extreme-pro-128gb/2.jpg", "/assets/sandisk-extreme-pro-128gb/3.webp"],
        category: "Memory Cards",
        description: "The SanDisk 128GB Extreme PRO SDXC UHS-I Card is perfect for professionals looking to maximize post-production workflow with fast speed and high capacity.",
        specifications: [
          "Capacity: 128GB",
          "Read speeds up to 170MB/s",
          "Write speeds up to 90MB/s",
          "UHS Speed Class 3 (U3)",
          "Video Speed Class 30 (V30)",
          "4K UHD and Full HD video ready",
          "Shock-proof, temperature-proof, waterproof, and X-ray-proof",
          "Lifetime limited warranty",
          "RescuePRO Deluxe data recovery software included",
          "Dimensions: 32 x 24 x 2.1 mm"
        ],
        reviews: [
          { id: 1, author: "Mark T.", rating: 5, comment: "Blazing fast read/write speeds. Perfect for my 4K video work." },
          { id: 2, author: "Sarah L.", rating: 4, comment: "Reliable and high-capacity, but a bit pricey compared to competitors." }
        ]
      },
      {
        id: 39,
        name: "Sony 64GB SF-G Tough Series UHS-II SDXC Memory Card",
        slug: "sony-tough-64gb",
        brand: "sony",
        price: 159,
        currency: "AED",
        images: ["/assets/sony-tough-64gb/1.jpg", "/assets/sony-tough-64gb/2.jpeg", "/assets/sony-tough-64gb/3.webp"],
        category: "Memory Cards",
        description: "The Sony 64GB SF-G Tough Series UHS-II SDXC Memory Card is designed for professional photographers and videographers who need reliability and extreme speeds.",
        specifications: [
          "Capacity: 64GB",
          "Read speeds up to 300MB/s",
          "Write speeds up to 299MB/s",
          "UHS-II, Class 10, U3 compatible",
          "Waterproof, dustproof, and bendproof",
          "Ribless design with no write-protection switch",
          "Supports 4K, 6K, and 8K video recording",
          "File Rescue software included",
          "Operating temperature: -25°C to 85°C",
          "Dimensions: 32 x 24 x 2.1 mm"
        ],
        reviews: [
          { id: 1, author: "Alex R.", rating: 5, comment: "Incredibly fast and durable. Worth every penny for professional use." },
          { id: 2, author: "Emily W.", rating: 4, comment: "Excellent performance, but make sure your devices support UHS-II for full speed." }
        ]
      },
      {
        id: 40,
        name: "Lexar 256GB Professional 1667x UHS-II SDXC Memory Card",
        slug: "lexar-1667x-256gb",
        brand: "lexar",
        price: 249,
        currency: "AED",
        images: ["/assets/lexar-1667x-256gb/1.jpg", "/assets/lexar-1667x-256gb/2.jpeg", "/assets/lexar-1667x-256gb/3.png"],
        category: "Memory Cards",
        description: "The Lexar 256GB Professional 1667x UHS-II SDXC Memory Card offers high-speed performance for photographers and videographers working with high-resolution images and 4K video.",
        specifications: [
          "Capacity: 256GB",
          "Read speeds up to 250MB/s",
          "Write speeds up to 90MB/s",
          "UHS-II, U3, Class 10",
          "V60-rated for 4K video recording",
          "Backwards compatible with UHS-I devices",
          "Includes SD UHS-II reader for high-speed file transfer",
          "Limited lifetime warranty",
          "Operating temperature: 0°C to 70°C",
          "Dimensions: 32 x 24 x 2.1 mm"
        ],
        reviews: [
          { id: 1, author: "Chris M.", rating: 5, comment: "Fantastic capacity and speed. Handles my 4K video workflow with ease." },
          { id: 2, author: "Lisa K.", rating: 4, comment: "Great performance, but make sure your camera supports UHS-II for best results." }
        ]
      },
      {
        id: 41,
        name: "ProGrade Digital 128GB CFexpress 2.0 Gold Memory Card",
        slug: "prograde-cfexpress-128gb",
        brand: "prograde",
        price: 399,
        currency: "AED",
        images: ["/assets/prograde-cfexpress-128gb/1.jpg", "/assets/prograde-cfexpress-128gb/2.webp", "/assets/prograde-cfexpress-128gb/3.webp"],
        category: "Memory Cards",
        description: "The ProGrade Digital 128GB CFexpress 2.0 Gold Memory Card is designed for professional photographers and videographers using high-end cameras with CFexpress Type B slots.",
        specifications: [
          "Capacity: 128GB",
          "Read speeds up to 1700MB/s",
          "Write speeds up to 1500MB/s",
          "PCIe Gen 3 interface",
          "NVMe protocol",
          "Optimized for 4K, 6K, and 8K video",
          "Designed for high-speed burst mode photography",
          "Thermal throttling to maintain card health",
          "3-year warranty",
          "Dimensions: 38.5 x 29.8 x 3.8 mm"
        ],
        reviews: [
          { id: 1, author: "Michael B.", rating: 5, comment: "Incredibly fast. Perfect for my high-end mirrorless camera and 8K video work." },
          { id: 2, author: "Jennifer T.", rating: 4, comment: "Amazing performance, but ensure your camera supports CFexpress Type B." }
        ]
      },
      {
        id: 42,
        name: "Kingston Canvas React Plus 64GB SDXC UHS-II Memory Card",
        slug: "kingston-canvas-react-plus-64gb",
        brand: "kingston",
        price: 129,
        currency: "AED",
        images: ["/assets/kingston-canvas-react-plus-64gb/1.jpg", "/assets/kingston-canvas-react-plus-64gb/2.jpeg", "/assets/kingston-canvas-react-plus-64gb/3.avif"],
        category: "Memory Cards",
        description: "The Kingston Canvas React Plus 64GB SDXC UHS-II Memory Card is designed for creative professionals who need high-speed performance for both photography and video.",
        specifications: [
          "Capacity: 64GB",
          "Read speeds up to 300MB/s",
          "Write speeds up to 260MB/s",
          "UHS-II, U3, V90 speed class ratings",
          "Ideal for 4K/8K Ultra-HD video production",
          "Supports high-speed burst mode shooting",
          "Durable design: waterproof, shockproof, X-ray proof",
          "Includes MobileLite Plus SD Reader",
          "Lifetime warranty",
          "Dimensions: 32 x 24 x 2.1 mm"
        ],
        reviews: [
          { id: 1, author: "David L.", rating: 5, comment: "Excellent performance for both stills and 4K video. The included reader is a nice bonus." },
          { id: 2, author: "Rachel S.", rating: 4, comment: "Fast and reliable, but make sure your camera can utilize UHS-II speeds." }
        ]
      }


    // Add more products with detailed information...
  ];
  
  export default products;
import React, { useState, useEffect } from 'react';
import { formatCurrency } from '../utils/formatCurrency';
import { Link } from 'react-router-dom';
import { UserIcon, ShoppingBagIcon, CogIcon, HeartIcon } from '@heroicons/react/outline';
import { FaCheckToSlot, FaGear } from "react-icons/fa6";
import { FaRegCreditCard, FaTruck } from "react-icons/fa";
import { TbLocationFilled } from "react-icons/tb";
import { LuPackageCheck, LuPackage2 } from "react-icons/lu";

const API_URL = process.env.REACT_APP_API_URL;

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const ordersPerPage = 2;

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No authentication token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_URL}/get-orders`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setOrders(data);
        } else {
          throw new Error('Failed to fetch orders');
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  if (loading) {
    return <div>Loading order history...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const quickActions = [
    { name: 'Edit Profile', icon: UserIcon, link: '/edit-profile' },
    { name: 'My Orders', icon: ShoppingBagIcon, link: '/orders' },
    { name: 'Account Settings', icon: CogIcon, link: '/settings' },
    { name: 'Wishlist', icon: HeartIcon, link: '/wishlist' },
  ];

  const timeline = [
    {
      id: 1,
      content: 'Order placed successfully.',
      target: '',
      date: 'Jan 1',
      datetime: '2024-01-01',
      icon: FaCheckToSlot,
      iconBackground: 'bg-green-500',
    },
    {
      id: 2,
      content: 'Payment confirmed.',
      target: '',
      date: 'Jan 2',
      datetime: '2024-01-02',
      icon: FaRegCreditCard,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 3,
      content: 'Order is being processed.',
      target: '',
      date: 'Jan 3',
      datetime: '2024-01-03',
      icon: FaGear,
      iconBackground: 'bg-orange-500',
    },
    {
      id: 4,
      content: 'Order shipped via FedEx.',
      target: '',
      date: 'Jan 4',
      datetime: '2024-01-04',
      icon: FaTruck,
      iconBackground: 'bg-yellow-500',
    },
    {
      id: 5,
      content: 'Order is out for delivery.',
      target: '',
      date: 'Jan 5',
      datetime: '2024-01-05',
      icon: TbLocationFilled,
      iconBackground: 'bg-purple-500',
    },
    {
      id: 6,
      content: 'Order delivered to John Doe.',
      target: '',
      date: 'Jan 6',
      datetime: '2024-01-06',
      icon: LuPackageCheck,
      iconBackground: 'bg-green-500',
    },
  ];
  

  return (
    <div className="flex">
      {/* Dashboard Menu */}
      <div>
        <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Quick Actions</h2>
            <div className="grid grid-cols-2 gap-4">
                {quickActions.map((action) => (
                <Link
                    key={action.name}
                    to={action.link}
                    className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg hover:bg-gray-200 transition duration-300"
                >
                    <action.icon className="h-8 w-8 text-gray-600 mb-2" />
                    <span className="text-sm text-center">{action.name}</span>
                </Link>
                ))}
            </div>
            <Link 
                to="/account" 
                className="flex items-center justify-center w-full py-3 px-4 mt-4 bg-amber-400 text-white rounded-lg hover:bg-amber-600 transition duration-300"
            >
                Back to Dashboard
            </Link>
            </div>
        </div>

      {/* Orders List */}
      <div className="w-3/4 p-4">
        <h2 className="text-2xl font-bold mb-4">Order History</h2>
        {orders.length === 0 ? (
          <p>You haven't placed any orders yet.</p>
        ) : (
          <>
            <ul>
              {currentOrders.map((order) => (
                <li key={order.orderNumber} className="mb-4 p-4 border rounded cursor-pointer" onClick={() => handleOrderClick(order)}>
                  <p><strong>Order Number:</strong> {order.orderNumber}</p>
                  <p><strong>Items:</strong> {order.items[0]?.name}</p>
                  <p><strong>Date:</strong> {new Date(order.date).toLocaleString()}</p>
                  <p><strong>Total:</strong> {formatCurrency(order.totalAmount)}</p>
                </li>
              ))}
            </ul>

            {/* Pagination */}
            <div className="flex justify-center mt-4">
              {Array.from({ length: Math.ceil(orders.length / ordersPerPage) }, (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 px-3 py-1 border rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white'}`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Order Details Modal */}
      {selectedOrder && (
        <div
          className="fixed inset-0 bg-slate-800 bg-opacity-50 overflow-y-auto h-full w-full"
          onClick={() => setSelectedOrder(null)}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-11/12 max-w-4xl shadow-lg rounded-md bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-bold text-slate-800 mb-4">Order Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-slate-800">
                  <strong>Order Number:</strong> {selectedOrder.orderNumber}
                </p>
                <p className="text-slate-800">
                  <strong>Status:</strong> {selectedOrder.status === "pending" && selectedOrder.paymentMethod === "cod"
                    ? "Order is being processed"
                    : selectedOrder.status}
                </p>
                <p className="text-slate-800">
                  <strong>Date:</strong> {new Date(selectedOrder.date).toLocaleString()}
                </p>
                <p className="text-slate-800">
                  <strong>Total:</strong> {formatCurrency(selectedOrder.totalAmount)}
                </p>
                <p className="text-slate-800">
                  <strong>Payment Method:</strong>{" "}
                  {selectedOrder.paymentMethod === "cod"
                    ? "Cash on Delivery"
                    : selectedOrder.paymentMethod}
                </p>
              </div>
              <div>
                <h4 className="font-bold text-slate-800">Shipping Address:</h4>
                <p className="text-slate-800">
                  {selectedOrder.shippingAddress.firstName}{" "}
                  {selectedOrder.shippingAddress.lastName}
                </p>
                <p className="text-slate-800">{selectedOrder.shippingAddress.address}</p>
                <p className="text-slate-800">{selectedOrder.shippingAddress.address2}</p>
                <p className="text-slate-800">
                  {selectedOrder.shippingAddress.city}, {selectedOrder.shippingAddress.zip}
                </p>
                <p className="text-slate-800">{selectedOrder.shippingAddress.country}</p>
              </div>
            </div>
            
            <h4 className="font-bold text-slate-800 mt-4">Items:</h4>
            <ul className="list-disc list-inside text-slate-800">
              {selectedOrder.items.map((item, index) => (
                <li key={index} className="flex items-center mb-2">
                <LuPackage2 className="h-5 w-5 text-amber-400 mr-2" />
                {item.name} - Quantity: {item.quantity}
              </li>
              ))}
            </ul>

            <h4 className="font-bold text-slate-800 mt-4">Order Progress</h4>
            <div className="mt-4">
              <div className="relative pt-1">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block text-slate-800">
                      {selectedOrder.status === "pending" && selectedOrder.paymentMethod === "cod"
                        ? "Order is being processed"
                        : selectedOrder.status}
                    </span>
                  </div>
                  <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-slate-800">
                      {selectedOrder.status === "delivered" ? "Delivered" : "In Progress"}
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                  <div
                    style={{ width: `${
                      selectedOrder.status === "pending"
                        ? "25%"
                        : selectedOrder.status === "processed"
                        ? "50%"
                        : selectedOrder.status === "shipped"
                        ? "75%"
                        : "100%"
                    }` }}
                    className="flex flex-col text-center whitespace-nowrap text-white justify-center bg-amber-400"
                  ></div>
                </div>
              </div>
            </div>

            <button
              className="mt-4 bg-amber-400 text-white px-4 py-2 rounded hover:bg-amber-500"
              onClick={() => setSelectedOrder(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}



    </div>
  );
};

export default AllOrders;
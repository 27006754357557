import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ProductCardWithHover from '../components/ProductCardWithHover';
import { ChevronRightIcon } from '@heroicons/react/solid';
import products from '../data/products';

const CategoryProductsPage = () => {
  const { category } = useParams();
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryProducts = () => {
      setLoading(true);
      setError(null);
      try {
        console.log('Fetching products for category:', category);
        console.log('All products:', products);

        // Helper function to check if a product belongs to a category
        const belongsToCategory = (product, targetCategory) => {
          const productCategory = product.category.toLowerCase().replace(/\s+/g, '-');
          const target = targetCategory.toLowerCase().replace(/\s+/g, '-');

          // Handle plural forms and special cases
          if (target === 'cameras' && productCategory === 'camera') return true;
          if (target === 'lenses' && productCategory === 'lens') return true;
          if (target === 'gimbals' && productCategory === 'gimbal') return true;
          if (target === 'memory-cards' && productCategory === 'memory-cards') return true;

          // Group accessories
          if (target === 'accessories' && 
              !['camera', 'lens', 'gimbal', 'memory-cards'].includes(productCategory) &&
              productCategory !== 'accessories') {
            return true;
          }

          return productCategory.includes(target) || target.includes(productCategory);
        };

        // Filter products based on the category
        const filteredProducts = products.filter(product => belongsToCategory(product, category));

        console.log('Filtered products:', filteredProducts);

        if (filteredProducts.length === 0) {
          setError('No products found in this category.');
        } else {
          setCategoryProducts(filteredProducts);
        }
      } catch (error) {
        console.error('Error fetching category products:', error);
        setError('An error occurred while fetching products.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryProducts();
  }, [category]);

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  // Format the category name for display
  const formatCategoryName = (cat) => {
    return cat.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const displayCategory = formatCategoryName(category);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-4">
        <Link to="/" className="text-gray-600 hover:text-gray-800">
          Home
        </Link>
        <ChevronRightIcon className="h-5 w-5 mx-2 text-gray-400" />
        <span className="text-gray-800 font-semibold">{displayCategory}</span>
      </div>

      <h1 className="text-3xl font-bold mb-6">{displayCategory} Products</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {categoryProducts.map((product) => (
          <ProductCardWithHover key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default CategoryProductsPage;
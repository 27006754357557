import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { FaAngleDown } from "react-icons/fa";
import { formatCurrency } from '../utils/formatCurrency';
import AuthForm from "../components/AuthForm";
import CheckoutProgress from "../components/CheckoutProgress";
import StepHeader from '../components/StepHeader';
import { FaCheck } from 'react-icons/fa';

const API_URL = process.env.REACT_APP_API_URL;

const paymentMethods = [{ id: "cod", title: "Cash on delivery" }];

const placeholderImage = "https://via.placeholder.com/150";

const Checkout = () => {
  const { isAuthenticated, login, cart, cartTotal, removeFromCart, clearCart } =
    useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const steps = [
    "User Details",
    "Shipping Address",
    "Shipping Method",
    "Payment",
  ];
  /* const orderSummaryRef = useRef(null);
  const progressBarRef = useRef(null); */
  const [error, setError] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [expandedStep, setExpandedStep] = useState(1);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contactInfo, setContactInfo] = useState({ fullName: "", email: "" });
  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
  });
  const [billingAddressSameAsShipping, setBillingAddressSameAsShipping] =
    useState(true);
  const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
  });
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    nameOnCard: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("cod");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const formatCurrency = (amount) => {
    return (Math.round(amount * 100) / 100).toFixed(2);
  };

  const LoadingSpinner = () => (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 className="text-center text-white text-xl font-semibold">
        Loading...
      </h2>
      <p className="w-1/3 text-center text-white">
        This may take a few seconds, please don't close this page.
      </p>
    </div>
  );

  useEffect(() => {
    const checkAuthAndFetchDetails = async () => {
      setIsLoading(true);
      try {
        if (isAuthenticated) {
          await fetchUserDetails();
        }
      } catch (error) {
        console.error("Error checking auth or fetching details:", error);
      } finally {
        setIsLoading(false);
      }
    };
    checkAuthAndFetchDetails();
  }, [isAuthenticated]);

  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const userData = await response.json();
        setContactInfo({
          email: userData.email || "",
          fullName: userData.name || "",
        });
        setShippingAddress({
          ...shippingAddress,
          firstName: userData.shippingAddress.firstName || "",
          lastName: userData.shippingAddress.lastName || "",
          address: userData.shippingAddress.address || "",
          address2: userData.shippingAddress.address2 || "",
          city: userData.shippingAddress.city || "",
          zip: userData.shippingAddress.zip || "",
          state: userData.shippingAddress.state || "",
          phone: userData.shippingAddress.phone || "",
        });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(email, password);
      setShowLoginForm(false);
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextStep = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
      setExpandedStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setExpandedStep(currentStep - 1);
    }
  };

  const getShippingCost = () => {
    switch (selectedShippingMethod) {
      case "standard":
        return 12.89;
      case "express":
        return 56.89;
      default:
        return 0;
    }
  };

  const shippingCost = getShippingCost();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      // Collect all the form data
      const orderData = {
        contactInfo,
        shippingAddress,
        billingAddress: billingAddressSameAsShipping
          ? shippingAddress
          : billingAddress,
        shippingMethod: selectedShippingMethod,
        paymentMethod,
        paymentInfo: paymentMethod === "cod" ? null : paymentInfo,
        cartItems: cart,
        cartTotal: parseFloat(formatCurrency(cartTotal)),
        shippingCost: parseFloat(formatCurrency(shippingCost)),
        vat: parseFloat(formatCurrency(cartTotal * 0.05)),
        totalAmount: parseFloat(
          formatCurrency(cartTotal + cartTotal * 0.05 + shippingCost),
        ),
        agreeToTerms,
      };

      // Log the collected data
      console.log("Order submitted:", orderData);

      // You can add validation here before proceeding
      if (!agreeToTerms) {
        console.error("You must agree to the terms and conditions");
        return;
      }

      if (!selectedShippingMethod) {
        console.error("Please select a shipping method");
        return;
      }

      try {
        const orderResponse = await fetch(`${API_URL}/submit-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(orderData),
        });
        if (!orderResponse.ok) {
          throw new Error(
            `Order submission failed with status ${orderResponse.status}`,
          );
        }
        const responseData = await orderResponse.json();
        if (
          responseData.message === "Order placed successfully" &&
          responseData.orderId
        ) {
          clearCart();
          navigate("/order-complete", {
            state: {
              orderId: responseData.orderId,
              message: responseData.message,
            },
          });
        }
      } catch (error) {
        console.error("Error submitting order:", error);
        setError("Failed to submit order. Please try again.");
      }
    } catch (error) {
      console.error("Error in order submission process:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = (stepNumber, title, content) => {
    const isCompleted = currentStep > stepNumber;
    const isActive = currentStep === stepNumber;
  
    return (
      <div className={`mb-4 ${isActive ? '' : 'border rounded-md p-4'}`}>
        <div
          className={`flex items-center cursor-pointer ${
            isActive ? 'mb-4' : ''
          }`}
          onClick={() => setCurrentStep(stepNumber)}
        >
          <div className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 ${
            isCompleted ? 'bg-amber-600' : isActive ? 'bg-amber-400' : 'bg-gray-300'
          }`}>
            {isCompleted ? (
              <FaCheck className="text-white" />
            ) : (
              <span className="text-white">{stepNumber}</span>
            )}
          </div>
          <h3 className="text-lg font-medium">{title}</h3>
        </div>
        {isActive && content}
      </div>
    );
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 pt-4 pb-4 sm:px-6 sm:pt-4 sm:pb-24 lg:px-8 xl:px-2 xl:pt-14">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Checkout
        </h1>

        <div className="mx-auto grid max-w-lg grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2 py-4">
          <div>
            <div className="mx-auto w-full max-w-lg">
              <h2 className="sr-only">Order summary</h2>

              <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                  {cart.map((item) => (
                    <li key={item.id} className="flex space-x-6 py-6">
                      <img
                        alt={item.name}
                        src={
                          Array.isArray(item.images)
                            ? item.images[0]
                            : item.images || placeholderImage
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = placeholderImage;
                        }}
                        className="size-24 flex-none rounded-md bg-gray-100 object-contain"
                      />
                      <div className="flex-auto">
                        <div className="space-y-1 sm:flex sm:items-start sm:justify-between sm:space-x-6">
                          <div className="flex-auto space-y-1 text-sm font-medium">
                            <h3 className="text-gray-900">
                              <a href={item.href}>{item.name}</a>
                            </h3>
                            <span>Quantity: {item.quantity}</span>
                            <p className="text-gray-900">
                              {formatCurrency(item.price * item.quantity)}
                            </p>
                            <p className="hidden text-gray-500 sm:block">
                              {item.color}
                            </p>
                            <p className="hidden text-gray-500 sm:block">
                              {item.size}
                            </p>
                          </div>
                          <div className="flex flex-none space-x-4">
                            <Link
                              to="/cart"
                              type="button"
                              className="text-sm font-medium text-amber-400 hover:text-indigo-500"
                            >
                              Edit
                            </Link>
                            <div className="flex border-l border-gray-300 pl-4">
                              <button
                                onClick={() => removeFromCart(item.id)}
                                type="button"
                                className="text-sm font-medium text-amber-400 hover:text-indigo-500"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900">{formatCurrency(cartTotal)}</dd>
                </div>
                <div className="flex justify-between">
                  <dt>Vat (5%)</dt>
                  <dd className="text-gray-900">
                    {formatCurrency(cartTotal * 0.05)}
                  </dd>
                </div>
                <div className="flex justify-between">
                  <dt>Shipping</dt>
                  <dd className="text-gray-900">
                    {selectedShippingMethod
                      ? formatCurrency(shippingCost)
                      : "Calculating"}
                  </dd>
                </div>

                <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">
                    {selectedShippingMethod
                      ? formatCurrency(
                          cartTotal + cartTotal * 0.05 + shippingCost,
                        )
                      : "Calculating"}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="mx-auto w-full max-w-lg">
            {/* <div>
              {isAuthenticated && (
                <CheckoutProgress currentStep={currentStep} steps={steps} />
              )}
            </div> */}

            <div>
              {!isAuthenticated && <AuthForm />}
              <div>
                {/* <div className="w-1/4 pr-4">
                  <div className="sticky top-4">
                    <VerticalProgressBar currentStep={currentStep} totalSteps={4} />
                  </div>
                </div> */}
                <div>
                  <form onSubmit={handleSubmit}>
                    {isAuthenticated && (
                      <>
                        {renderStep(
                          1,
                          "User Details",
                          <div className="mt-4">
                            <input
                              type="text"
                              value={contactInfo.fullName}
                              onChange={(e) =>
                                setContactInfo({
                                  ...contactInfo,
                                  fullName: e.target.value,
                                })
                              }
                              placeholder="Full Name"
                              className="w-full p-2 mb-2 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6 rounded"
                              required
                              disabled
                            />
                            <input
                              type="email"
                              value={contactInfo.email}
                              onChange={(e) =>
                                setContactInfo({
                                  ...contactInfo,
                                  email: e.target.value,
                                })
                              }
                              placeholder="Email"
                              className="w-full p-2 mb-2 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6 rounded"
                              required
                              disabled
                            />
                            <button
                              type="button"
                              onClick={handleNextStep}
                              className="bg-amber-400 shadow-lg text-white hover:bg-amber-600 py-2 px-4 rounded"
                            >
                              Continue
                            </button>
                          </div>,
                        )}

                        {renderStep(
                          2,
                          "Shipping Address",
                          <div className="mt-4">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 px-2 py-2">
                              <div className="relative sm:col-span-3 mt-2">
                                <label
                                  htmlFor="first-name"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  First Name
                                </label>
                                
                                  <input
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    placeholder="First Name"
                                    value={shippingAddress.firstName}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        firstName: e.target.value,
                                      })
                                    }
                                    autoComplete="given-name"
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>

                              <div className="relative sm:col-span-3 mt-2">
                                <label
                                  htmlFor="last-name"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  Last Name
                                </label>
          
                                  <input
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    placeholder="Last Name"
                                    value={shippingAddress.lastName}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        lastName: e.target.value,
                                      })
                                    }
                                    autoComplete="family-name"
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>

                              <div className="relative col-span-full mt-2">
                                <label
                                  htmlFor="address"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  Address
                                </label>
                                
                                  <input
                                    id="address"
                                    name="address"
                                    type="text"
                                    placeholder="Address"
                                    value={shippingAddress.address}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        address: e.target.value,
                                      })
                                    }
                                    autoComplete="street-address"
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>

                              <div className="relative col-span-full mt-2">
                                <label
                                  htmlFor="address2"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  Apartment, suite, etc.
                                </label>
                                
                                  <input
                                    id="address2"
                                    name="address2"
                                    type="text"
                                    placeholder="Apartment, suite, etc."
                                    value={shippingAddress.address2}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        address2: e.target.value,
                                      })
                                    }
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>

                              <div className="relative sm:col-span-2 sm:col-start-1 mt-2">
                                <label
                                  htmlFor="city"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  City
                                </label>
                                
                                  <input
                                    id="city"
                                    name="city"
                                    type="text"
                                    placeholder="City"
                                    value={shippingAddress.city}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        city: e.target.value,
                                      })
                                    }
                                    autoComplete="city"
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>

                              

                              <div className="relative sm:col-span-2 mt-2">
                                <label
                                  htmlFor="region"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  State / Province
                                </label>
                                
                                  <input
                                    id="state"
                                    name="state"
                                    type="text"
                                    placeholder="State / Province"
                                    value={shippingAddress.state}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        state: e.target.value,
                                      })
                                    }
                                    autoComplete="state"
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>

                              <div className="relative sm:col-span-2 mt-2">
                                <label
                                  htmlFor="postal-code"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  Postal code
                                </label>
                                
                                  <input
                                    id="zip"
                                    name="zip"
                                    type="text"
                                    placeholder="Postal code"
                                    value={shippingAddress.zip}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        zip: e.target.value,
                                      })
                                    }
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>

                              <div className="relative sm:col-span-3 mt-2">
                                <label
                                  htmlFor="country"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  Country
                                </label>
                                <select
                                  id="country"
                                  name="country"
                                  value={shippingAddress.country}
                                  onChange={(e) =>
                                    setShippingAddress({
                                      ...shippingAddress,
                                      country: e.target.value,
                                    })
                                  }
                                  autoComplete="country-name"
                                  className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6 appearance-none"
                                >
                                  
                                  <option value="United Arab Emirates">United Arab Emirates</option>
                                  {/* Add more country options here if needed */}
                                </select>
                                <FaAngleDown
                                  aria-hidden="true"
                                  className="pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 text-gray-500"
                                />
                              </div>

                              <div className="relative sm:col-span-3 mt-2">
                                <label
                                  htmlFor="phone"
                                  className="absolute -top-2 left-2 inline-block rounded-lg bg-white px-1 text-xs font-medium text-gray-900"
                                >
                                  Phone
                                </label>
                                
                                  <input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    placeholder="Phone Number"
                                    value={shippingAddress.phone}
                                    onChange={(e) =>
                                      setShippingAddress({
                                        ...shippingAddress,
                                        phone: e.target.value,
                                      })
                                    }
                                    autoComplete="tel"
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-s-900 outline outline-1 -outline-offset-1 outline-slate-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-amber-400 sm:text-sm/6"
                                  />
                                
                              </div>
                             </div>
                          
                            {/* Add other shipping address fields here */}
                            <div className="mt-4">
                              <input
                                type="checkbox"
                                checked={billingAddressSameAsShipping}
                                onChange={(e) =>
                                  setBillingAddressSameAsShipping(e.target.checked)
                                }
                                id="sameAsBilling"
                              />
                              <label htmlFor="sameAsBilling" className="ml-2">
                                Billing address same as shipping
                              </label>
                            </div>
                            {!billingAddressSameAsShipping && (
                              <div className="mt-6">
                                <h3>Billing Address</h3>
                                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                  <div>
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      First name
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="first-name"
                                        name="first-name"
                                        type="text"
                                        placeholder="First Name"
                                        value={billingAddress.firstName}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            firstName: e.target.value,
                                          })
                                        }
                                        autoComplete="given-name"
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      Last name
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="last-name"
                                        name="last-name"
                                        type="text"
                                        placeholder="Last Name"
                                        value={billingAddress.lastName}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            lastName: e.target.value,
                                          })
                                        }
                                        autoComplete="family-name"
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="address"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      Address
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="address"
                                        name="address"
                                        type="text"
                                        placeholder="Address"
                                        value={billingAddress.address}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            address: e.target.value,
                                          })
                                        }
                                        autoComplete="street-address"
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="address2"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      Apartment, suite, etc.
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="address2"
                                        name="address2"
                                        type="text"
                                        placeholder="Apartment, suite, etc."
                                        value={billingAddress.address2}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            address2: e.target.value,
                                          })
                                        }
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor="city"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      City
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="city"
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        value={billingAddress.city}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            city: e.target.value,
                                          })
                                        }
                                        autoComplete="city"
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor="country"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      Country
                                    </label>
                                    <div className="mt-2 grid grid-cols-1">
                                      <select
                                        id="country"
                                        name="country"
                                        value={billingAddress.country}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            country: e.target.value,
                                          })
                                        }
                                        autoComplete="country-name"
                                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pr-8 pl-3 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      >
                                        <option>United Arab Emirates</option>
                                      </select>
                                      <FaAngleDown
                                        aria-hidden="true"
                                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor="region"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      State / Province
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="state"
                                        name="state"
                                        type="text"
                                        placeholder="State / Province"
                                        value={billingAddress.state}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            state: e.target.value,
                                          })
                                        }
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor="postal-code"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      Postal code
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="zip"
                                        name="zip"
                                        type="text"
                                        placeholder="Postal code"
                                        value={billingAddress.zip}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            zip: e.target.value,
                                          })
                                        }
                                        autoComplete="postal-code"
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                    <label
                                      htmlFor="phone"
                                      className="block text-sm/6 font-medium text-gray-700"
                                    >
                                      Phone
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        placeholder="Phone Number"
                                        value={billingAddress.phone}
                                        onChange={(e) =>
                                          setBillingAddress({
                                            ...billingAddress,
                                            phone: e.target.value,
                                          })
                                        }
                                        autoComplete="tel"
                                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                                      />
                                    </div>
                                  </div>
                                  {/* Add other billing address fields here */}
                                </div>
                              </div>
                            )}
                            <div className="mt-4">
                              <button
                                type="button"
                                onClick={handlePreviousStep}
                                className="bg-gray-300 text-black py-2 px-4 rounded mr-2"
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                onClick={handleNextStep}
                                className="bg-amber-400 hover:bg-amber-600 text-white py-2 px-4 rounded"
                              >
                                Next
                              </button>
                            </div>
                          </div>,
                        )}

                        {renderStep(
                          3,
                          "Shipping Method",
                          <div className="mt-4">
                            <div className="space-y-2">
                              <div className="flex items-center px-4">
                                <input
                                  id="standard-shipping"
                                  name="shipping-method"
                                  type="radio"
                                  checked={selectedShippingMethod === "standard"}
                                  onChange={() =>
                                    setSelectedShippingMethod("standard")
                                  }
                                  className="h-4 w-4 border-gray-300 text-amber-600 focus:ring-amber-600"
                                />
                                <label
                                  htmlFor="standard-shipping"
                                  className="ml-2 block text-sm text-gray-900"
                                >
                                  Standard Shipping (AED 12.89)
                                </label>
                              </div>
                              <div className="flex items-center px-4">
                                <input
                                  id="express-shipping"
                                  name="shipping-method"
                                  type="radio"
                                  checked={selectedShippingMethod === "express"}
                                  onChange={() =>
                                    setSelectedShippingMethod("express")
                                  }
                                  className="h-4 w-4 border-gray-300 text-amber-600 focus:ring-amber-600"
                                />
                                <label
                                  htmlFor="express-shipping"
                                  className="ml-2 block text-sm text-gray-900"
                                >
                                  Express Shipping (AED 56.89)
                                </label>
                              </div>
                            </div>
                            <div className="mt-4">
                              <button
                                type="button"
                                onClick={handlePreviousStep}
                                className="bg-gray-300 text-black py-2 px-4 rounded mr-2"
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                onClick={handleNextStep}
                                className="bg-amber-400 hover:bg-amber-600 text-white py-2 px-4 rounded"
                              >
                                Next
                              </button>
                            </div>
                          </div>,
                        )}

                        {renderStep(
                          4,
                          "Payment Methods",
                          <div className="mt-4">
                            <fieldset className="border-t border-gray-200 mt-4 pt-4">
                              <legend className="text-sm/6 font-semibold text-gray-900">
                                Payment methods
                              </legend>
                              <p className="mt-1 text-sm/6 text-gray-600">
                                Select a payment method
                              </p>
                              <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                {paymentMethods.map((paymentMethod) => (
                                  <div
                                    key={paymentMethod.id}
                                    className="flex items-center"
                                  >
                                    <input
                                      defaultChecked={paymentMethod.id === "cod"}
                                      id={paymentMethod.id}
                                      name="payment-method"
                                      type="radio"
                                      className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white not-checked:before:hidden checked:border-amber-400 checked:bg-amber-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden"
                                    />
                                    <label
                                      htmlFor={paymentMethod.id}
                                      className="ml-3 block text-sm/6 font-medium text-gray-900"
                                    >
                                      {paymentMethod.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>

                            {/* Add other payment fields here */}
                            <div className="mt-4">
                              <input
                                type="checkbox"
                                checked={agreeToTerms}
                                onChange={(e) => setAgreeToTerms(e.target.checked)}
                                id="agreeTerms"
                              />
                              <label htmlFor="agreeTerms" className="ml-2">
                                I agree to the terms and conditions
                              </label>
                            </div>
                            <div className="mt-4">
                              <button
                                type="button"
                                onClick={handlePreviousStep}
                                className="bg-gray-300 text-black py-2 px-4 rounded mr-2"
                              >
                                Previous
                              </button>
                              <button
                                type="submit"
                                disabled={!agreeToTerms}
                                className="bg-amber-500 text-white py-2 px-4 rounded disabled:bg-gray-300"
                              >
                                Place Order
                              </button>
                            </div>
                          </div>,
                        )}
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>

            {/* <div className="lg:col-span-2">
          <div className="mx-auto max-w-lg">
            <h2 className="text-lg font-medium text-gray-900">Order summary</h2>
            <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="text-gray-900">${cartTotal.toFixed(2)}</dd>
              </div>
              <div className="flex justify-between">
                <dt>Taxes</dt>
                <dd className="text-gray-900">${(cartTotal * 0.08).toFixed(2)}</dd>
              </div>
              <div className="flex justify-between">
                <dt>Shipping</dt>
                <dd className="text-gray-900">$14.00</dd>
              </div>
              <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt className="text-base">Total</dt>
                <dd className="text-base">${(cartTotal + (cartTotal * 0.08) + 14).toFixed(2)}</dd>
              </div>
            </dl>
          </div>
        </div> */}
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Checkout;

import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div
      className="relative h-[350px] bg-no-repeat bg-right bg-contain flex items-center"
      style={{
        backgroundImage: `url('/assets/featured/old-camera-vintage.jpg')`, // Replace with your actual image URL
      }}
    >
      {/* Overlay (Optional for contrast) */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/30 to-black/10"></div>

      {/* Text and Button */}
      <div className="relative z-10 text-white max-w-2xl px-4">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Check out the latest arrivals!
        </h1>
        <p className="text-lg md:text-xl mb-6">
          Discover our newest collection of cutting-edge products.
        </p>
        <Link to="/new-arrivals" className="px-6 py-3 bg-amber-500 hover:bg-orange-600 text-lg font-semibold rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105">
          View All New Arrivals
        </Link>
      </div>
    </div>
  );
};

export default Banner;

import React, { useState, useEffect } from "react";
//import HeroSlider from "../components/HeroSlider";
import ProductCard from "../components/ProductCard";
import EnhancedProductCard from "../components/EnhancedProductCard";
import EnhancedCategoryCard from "../components/EnhancedCategoryCard";
import products from "../data/products";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import FeaturedProductBanner from "../components/FeaturedProductBanner";
import CustomHeroSlider from "../components/CustomHeroSlider";
import BestSellingProducts from "../components/BestSellingProducts";
import NewArrivalsBanner from "../components/NewArrivalsBanner";
import SaleCountdown from "../components/SaleCountdown";
import BlogPostCard from "../components/BlogPostCard";
import { motion } from 'framer-motion';
import SaleProductCard from "../components/SaleProductCard";
import DealsAndNewsSection from "../components/DealsAndNewsSection";
import NewBanner from "../components/NewBanner";
import BrandCard from "../components/BrandCard";

const brands = [
  { name: 'Canon', logo: '/assets/brands/canon.svg' },
  { name: 'Nikon', logo: '/assets/brands/nikon.svg' },
  { name: 'Sony', logo: '/assets/brands/sony.svg' },
  { name: 'Sigma', logo: '/assets/brands/sigma.svg' },
  { name: 'GoPro', logo: '/assets/brands/gopro.png' },
  { name: 'Dji', logo: '/assets/brands/dji.svg' },
  { name: 'Black Magic', logo: '/assets/brands/blackmagic.svg' },
  { name: 'GoDox', logo: '/assets/brands/godox.svg' },
];


const categories = [
  { 
    name: "Cameras", 
    image: "/assets/camera-category.webp",
    description: "Explore our wide range of high-quality cameras for professionals and enthusiasts."
  },
  { 
    name: "Lenses", 
    image: "/assets/lens-category.webp",
    description: "Discover the perfect lens to capture your vision with clarity and precision."
  },
  { 
    name: "Gimbals", 
    image: "/assets/gimbal-category.webp",
    description: "Achieve smooth, professional-grade footage with our selection of gimbals."
  },
  { 
    name: "Accessories", 
    image: "/assets/accessory-category.webp",
    description: "Enhance your photography gear with our essential accessories collection."
  },
];

const Home = () => {

  // Assume the first product is the featured product for this example
  const featuredProduct = products.length > 0 ? products[0] : null;

  // Filter popular products (products with average rating >= 4)
  const popularProducts = products.filter(product => {
    const avgRating = product.reviews.reduce((sum, review) => sum + review.rating, 0) / product.reviews.length;
    return avgRating >= 4;
  });

  // Filter best selling products (products with average rating <= 4)
  const bestSellingProducts = products.filter(product => {
    const avgRating = product.reviews && product.reviews.length > 0
      ? product.reviews.reduce((sum, review) => sum + review.rating, 0) / product.reviews.length
      : 0;
    return avgRating <= 4;
  });

  // Sort products by date (assuming there's a 'createdAt' field) and get the latest 4
  const newArrivals = [...products]
    .filter(product => product.image && product.image.length > 0)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 6);

  // Filter popular products (products with average rating >= 4)
  const specialProducts = products.filter(product => {
    const avgRating = product.reviews.reduce((sum, review) => sum + review.rating, 0) / product.reviews.length;
    return avgRating >= 4;
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  //const popularProducts = products.slice(0, 8); // Assuming we want to show 8 popular products

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex + 4 >= popularProducts.length ? 0 : prevIndex + 4
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex - 4 < 0 ? popularProducts.length - 4 : prevIndex - 4
    );
  };

  return (
    <main className="container mx-auto px-6 py-10 space-y-10">
      {/* Hero Slider Section */}
       {/* <CustomHeroSlider /> */}

      {/* Categories Section */}
      <section>
        {/* <h2 className="text-3xl font-bold mb-6 text-gray-800">Shop by Category</h2> */}
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
          {categories.map((category) => (
            <EnhancedCategoryCard key={category.name} category={category} />
          ))}
        </div>
      </section>

      {/* Popular Products Section */}
      <section>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-bold text-gray-800">Popular Products</h2>
          <div className="flex space-x-2">
            <button onClick={prevSlide} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
              <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
            </button>
            <button onClick={nextSlide} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
              <ChevronRightIcon className="h-6 w-6 text-gray-600" />
            </button>
          </div>
        </div>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {popularProducts.slice(currentIndex, currentIndex + 4).map((product) => (
            <EnhancedProductCard key={product.id} product={product} />
          ))}
        </div>
      </section>

      {/* Featured Product Banner */}
      {featuredProduct && <FeaturedProductBanner product={featuredProduct} />}
      
      {/* Best Selling Products Section */}
      <BestSellingProducts products={bestSellingProducts} />

      {/* New Arrivals Banner */}
      {/* {newArrivals.length > 0 && <NewArrivalsBanner products={newArrivals} />} */}

      <NewBanner />

      {/* Popular Products Section */}
      <section>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-bold text-gray-800">Special Products</h2>
          <div className="flex space-x-2">
            <button onClick={prevSlide} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
              <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
            </button>
            <button onClick={nextSlide} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
              <ChevronRightIcon className="h-6 w-6 text-gray-600" />
            </button>
          </div>
        </div>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {specialProducts.slice(currentIndex, currentIndex + 4).map((product) => (
            <EnhancedProductCard key={product.id} product={product} />
          ))}
        </div>
      </section>

      {/* Sale and Blog Posts Section */}
        <section className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        {/* Sale Product Card */}
        <motion.div 
          className="lg:col-span-1 bg-white rounded-lg shadow-sm overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {products.length > 0 && (
            <SaleProductCard product={products[0]} />
          )}
        </motion.div>

        {/* Latest News and Blog Posts */}
        <motion.div 
          className="lg:col-span-3 bg-white rounded-lg shadow-sm overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="p-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">Latest News & Blog Posts</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
              <BlogPostCard 
                title="New Camera Tech"
                excerpt="Latest advancements..."
                image="/assets/new-camera-tech.jpg"
                date="2023-06-15"
              />
              <BlogPostCard 
                title="Top 5 Portrait Lenses"
                excerpt="Best lenses for portraits..."
                image="/assets/top-5-potrait-lens.webp"
                date="2023-06-10"
              />
              <BlogPostCard 
                title="Night Photography"
                excerpt="Capture stunning nights..."
                image="/assets/night-photography.jpeg"
                date="2023-06-05"
              />
            </div>
          </div>
        </motion.div>
      </section>
      <section>
      <h1 className="text-3xl font-bold text-slate-800 text-center mb-8">Explore Popular Brands</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {brands.map((brand, index) => (
          <BrandCard key={index} brand={brand} />
        ))}
      </div>
      </section>

    

      {/* Deals Section */}
      {/* <section className="bg-blue-100 p-6 rounded-lg">
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Special Deals</h2>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {products.slice(2, 6).map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </section> */}
    </main>
  );
};

export default Home;
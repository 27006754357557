import React from "react";
import { useLocation, Link } from "react-router-dom";

const OrderCompletePage = () => {
  const location = useLocation();
  const { orderId, message } = location.state || {};

  if (!orderId) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center text-red-600">Error</h1>
        <p className="text-center mt-4">
          Unable to retrieve order details. Please contact support.
        </p>
        <div className="text-center mt-6">
          <Link
            to="/"
            className="text-blue-600 hover:text-blue-800 font-semibold"
          >
            Go to Home Page
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">Order Complete</h1>
      {message && <p className="text-center text-xl mb-4">{message}</p>}
      {orderId && (
        <p className="text-center">
          Your order ID is: <span className="font-bold">{orderId}</span>
        </p>
        )}
        <p className="mt-4 text-center text-gray-600">
          We’ve sent a confirmation email with your order details. You can also
          view your order history in your account.
        </p>
        <div className="mt-6 flex justify-center">
          <Link
            to="/"
            className="bg-blue-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-700"
          >
            Continue Shopping
          </Link>
        </div>
      
    </div>
  );
};

export default OrderCompletePage;

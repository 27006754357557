import React, { useContext, useState, useRef, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import products from "../data/products";
import { AppContext } from "../context/AppContext";
import { StarIcon, HomeIcon, ChevronRightIcon } from '@heroicons/react/solid';
import ProductCard from "../components/ProductCard";

const ProductDetails = () => {
  const { slug, id } = useParams();
  const { addToCart } = useContext(AppContext);
  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const [mainImage, setMainImage] = useState(0);
  const [showZoom, setShowZoom] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    console.log("Slug:", slug);
    console.log("ID:", id);
    console.log("All products:", products);
  
    let foundProduct;
    if (slug) {
      // Try to find by slug as string
      foundProduct = products.find((p) => p.slug === slug);
      
  
      // If not found, try to find by slug as number
      if (!foundProduct) {
        foundProduct = products.find((p) => p.id === parseInt(slug));
        
      }
    }
    if (!foundProduct && id) {
      foundProduct = products.find((p) => p.id.toString() === id);
      
    }
  
    if (!foundProduct) {
      console.log("No product found with slug or id");
    }
  
    setProduct(foundProduct);
  }, [slug, id]);

  if (!product) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold text-red-600">Product Not Found</h2>
        <p>Unable to find product with slug: {slug} or id: {id}</p>
      </div>
    );
  }

  const relatedProducts = products.filter(p => p.category === product.category && p.id !== product.id).slice(0, 4);

  const handleMouseMove = (e) => {
    if (imageRef.current) {
      const { left, top, width, height } = imageRef.current.getBoundingClientRect();
      const x = ((e.pageX - left) / width) * 100;
      const y = ((e.pageY - top) / height) * 100;
      setZoomPosition({ x, y });
    }
  };

  // Ensure product.images exists and is an array
  const productImages = Array.isArray(product.images) ? product.images : 
                        (product.image ? [product.image] : []);

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Breadcrumbs */}
      <nav className="flex mb-8" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600">
              <HomeIcon className="w-4 h-4 mr-2" />
              Home
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="w-6 h-6 text-gray-400" />
              <Link to={`/category/${product.category}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2">
                {product.category}
              </Link>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <ChevronRightIcon className="w-6 h-6 text-gray-400" />
              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2">{product.name}</span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <div 
            className="relative overflow-hidden" 
            style={{ width: '400px', height: '400px' }}
            onMouseEnter={() => setShowZoom(true)}
            onMouseLeave={() => setShowZoom(false)}
            onMouseMove={handleMouseMove}
            ref={imageRef}
          >
            {productImages.length > 0 ? (
              <img 
                src={productImages[mainImage]} 
                alt={product.name} 
                className="w-full h-full object-contain rounded-lg shadow-lg"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-lg shadow-lg">
                <p>No image available</p>
              </div>
            )}
            {showZoom && productImages.length > 0 && (
              <div 
                className="absolute top-0 left-full ml-4 bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden"
                style={{ width: '400px', height: '400px' }}
              >
                <img 
                  src={productImages[mainImage]} 
                  alt={product.name} 
                  className="absolute w-[800px] h-[800px] max-w-none"
                  style={{
                    transform: `translate(-${zoomPosition.x}%, -${zoomPosition.y}%)`,
                  }}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-5 gap-2 mt-4">
            {productImages.map((img, index) => (
              <img 
                key={index}
                src={img} 
                alt={`${product.name} - ${index + 1}`}
                className={`w-full h-20 object-contain rounded cursor-pointer ${mainImage === index ? 'border-2 border-amber-400' : ''}`}
                onClick={() => setMainImage(index)}
              />
            ))}
          </div>
        </div>
        <div>
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <div className="flex items-center mb-4">
            <div className="flex text-yellow-400">
              {[...Array(5)].map((_, i) => (
                <StarIcon key={i} className="h-5 w-5" />
              ))}
            </div>
            <span className="ml-2 text-gray-600">({product.reviews.length} reviews)</span>
          </div>
          <p className="text-2xl font-semibold text-blue-600 mb-4">{product.price} {product.currency}</p>
          <p className="text-gray-600 mb-4">Category: <Link to={`/category/${product.category}`}>{product.category}</Link></p>
          <p className="text-gray-600 mb-4">Brand: <Link to={`/brands/${product.brand}`}>{product.brand.charAt(0).toUpperCase() + product.brand.slice(1)}</Link></p>
          <p className="text-gray-700 mb-6">{product.description}</p>
          <button 
            onClick={() => addToCart(product)} 
            className="bg-amber-400 text-white py-2 px-4 rounded shadow-lg hover:bg-amber-600 transition duration-300 mb-6"
          >
            Add to Cart
          </button>
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">Product Specifications</h2>
            <ul className="list-disc list-inside text-gray-700">
              {product.specifications.map((spec, index) => (
                <li key={index}>{spec}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {['description', 'reviews'].map((tab) => (
              <button
                key={tab}
                className={`${
                  activeTab === tab
                    ? 'border-amber-400 text-amber-400'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
        </div>

        <div className="mt-8">
          {activeTab === 'description' && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Product Description</h2>
              <p className="text-gray-700">{product.description}</p>
            </div>
          )}

          {activeTab === 'reviews' && (
            <div>
              <h2 className="text-xl font-semibold mb-4">Customer Reviews</h2>
              {product.reviews.map((review) => (
                <div key={review.id} className="mb-4 pb-4 border-b border-gray-200 last:border-b-0">
                  <div className="flex items-center mb-2">
                    <div className="flex text-yellow-400">
                      {[...Array(review.rating)].map((_, i) => (
                        <StarIcon key={i} className="h-5 w-5" />
                      ))}
                    </div>
                    <span className="ml-2 font-semibold">{review.author}</span>
                  </div>
                  <p className="text-gray-700">{review.comment}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Related Products */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-6">Related Products</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {relatedProducts.map((product) => (
            <ProductCard key={product.slug} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AppContext } from '../context/AppContext';
import OrderHistory from '../components/OrderHistory';
import { Link, Navigate } from 'react-router-dom';
import { UserIcon, ShoppingBagIcon, CogIcon, HeartIcon } from '@heroicons/react/outline';
import '../styles/spinner.css';

const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);

  const LoadingSpinner = () => (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
      <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
    </div>
  );

  const fetchUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${API_URL}/user`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
       
        setUserDetails(data);
      } else {
        throw new Error('Failed to fetch user details');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  /* if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  } */

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const quickActions = [
    { name: 'Edit Profile', icon: UserIcon, link: '/edit-profile' },
    { name: 'My Orders', icon: ShoppingBagIcon, link: '/orders' },
    { name: 'Account Settings', icon: CogIcon, link: '/settings' },
    { name: 'Wishlist', icon: HeartIcon, link: '/wishlist' },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">My Account Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="md:col-span-2">
          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Account Information</h2>
            <p><strong>Name:</strong> {userDetails?.name || user?.username}</p>
            <p><strong>Email:</strong> {userDetails?.email || user?.email}</p>
          </div>
          {userDetails && userDetails.shippingAddress && (
            <div className="bg-white shadow rounded-lg p-6 mb-8">
              <h2 className="text-xl font-semibold mb-4">Shipping Address</h2>
              <p><strong>Name:</strong> {userDetails.shippingAddress.firstName || 'Not provided'} {userDetails.shippingAddress.lastName || 'Not provided'}</p>
              <p><strong>Address:</strong> {userDetails.shippingAddress.address || 'Not provided'}</p>
              <p><strong></strong>{userDetails.shippingAddress.address2 || 'Not provided'}</p>
              <p><strong>City:</strong> {userDetails.shippingAddress.city || 'Not provided'}</p>
              <p><strong>Postal Code:</strong> {userDetails.shippingAddress.zip || 'Not provided'}</p>
              <p><strong>Country:</strong> {userDetails.shippingAddress.country || 'Not provided'}</p>
            </div>
          )}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Recent Orders</h2>
            <OrderHistory limit={1} />
            <Link to="/orders" className="text-blue-500 hover:underline mt-4 inline-block">
              View All Orders
            </Link>
          </div>
        </div>

        <div>
          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Quick Actions</h2>
            <div className="grid grid-cols-2 gap-4">
              {quickActions.map((action) => (
                <Link
                  key={action.name}
                  to={action.link}
                  className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg hover:bg-gray-200 transition duration-300"
                >
                  <action.icon className="h-8 w-8 text-gray-600 mb-2" />
                  <span className="text-sm text-center">{action.name}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Dashboard;
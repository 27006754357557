import React from 'react';

const Legal = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Legal Information</h1>
      <div className="space-y-4">
        <section>
          <h2 className="text-2xl font-semibold mb-2">Company Information</h2>
          <p>Al Noori Store LLC<br />
          2 Maryam Mohammed Ahmed Al Otaiba Building - Deira - Al Baraha F.050 Office<br />
          Phone: +971 4 385 9367<br />
          Email: sales@alnooristore.com</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">Intellectual Property</h2>
          <p>All content on this website, including text, graphics, logos, and images, is the property of Al Noori Store and protected by UAE and international copyright laws.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">Disclaimer</h2>
          <p>The information provided on this website is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability of the information.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">Governing Law</h2>
          <p>These terms and conditions are governed by and construed in accordance with the laws of the United Arab Emirates. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of Dubai, UAE.</p>
        </section>
      </div>
    </div>
  );
};

export default Legal;
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BrandCard = ({ brand }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/brands/${brand.name.toLowerCase()}`);
  };

  const shouldInvertLogo = (brandName) => {
    const brandsToInvert = ['black magic', 'dji', 'sony']; // Add all brand names you want to invert
    return brandsToInvert.includes(brandName.toLowerCase());
  };

  return (
    <div
      onClick={handleClick}
      className="cursor-pointer bg-amber-100 hover:bg-amber-200 border border-slate-300 rounded-lg p-4 shadow-md transition-transform transform hover:scale-105"
    >
      <img 
        src={brand.logo} 
        alt={brand.name} 
        className={`h-10 mx-auto mb-2 ${shouldInvertLogo(brand.name) ? 'filter invert' : ''}`}
        style={shouldInvertLogo(brand.name) ? { filter: 'brightness(0) invert(0)' } : {}}
      />
      {/* <h3 className="text-center text-slate-700 font-semibold">{brand.name}</h3> */}
    </div>
  );
};

export default BrandCard;

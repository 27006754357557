import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { CSSTransition } from 'react-transition-group';
import { FaBars, FaSearch, FaShoppingCart, FaTags, FaTools, FaShippingFast, FaPhoneAlt, FaCamera, FaGripHorizontal, FaMemory, FaSuitcase, FaCogs } from 'react-icons/fa';
import { MdCameraRoll } from 'react-icons/md';
import { formatCurrency } from "../utils/formatCurrency";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import products from "../data/products";

const Header = () => {
  const { cart, user, logout, cartTotal } = useContext(AppContext);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const nodeRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  
    if (query.trim() === '') {
      setSearchResults([]);
    } else {
      const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredProducts.slice(0, 5)); // Limit to 5 results
    }
  };

  const handleProductSelect = (productId) => {
    setSearchQuery('');
    setSearchResults([]);
    navigate(`/product/${productId}`);
  };

  return (
    <header>
      {/* Top Bar - Always visible */}
      <div className="bg-gray-800 text-white">
        <div className="container mx-auto flex justify-between items-center py-2 px-4">
          {/* Left Links */}
          <nav className="flex gap-4 text-xs">
            <Link to="/" className="hover:text-yellow-500 transition-colors">Home</Link>
            <Link to="/about" className="hover:text-yellow-500 transition-colors">About Us</Link>
            <Link to="/contact" className="hover:text-yellow-500 transition-colors">Contact</Link>
          </nav>
          {/* Right Links */}
          <div className="flex items-center gap-4 text-xs">
            {user ? (
              <>
                <Link to="/account" className="hover:text-yellow-500 transition-colors">Account</Link>
                <button onClick={logout} className="hover:text-yellow-500 transition-colors">Logout</button>
              </>
            ) : (
              <>
                <Link to="/login" className="hover:text-yellow-500 transition-colors">Sign In</Link>
                <Link to="/register" className="hover:text-yellow-500 transition-colors">Register</Link>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Main Header - Not sticky */}
      <div className="bg-white">
        <div className="container mx-auto flex justify-between items-center py-4 px-4">
          {/* Logo and Store Name */}
          <div className="flex items-center">
            <Link to="/" className="text-3xl font-bold text-gray-800 hover:text-yellow-500 transition-colors">
              Al Noori <span className="text-yellow-500">Store</span>
            </Link>
          </div>

          {/* Features */}
          <div className="hidden lg:flex gap-8">
            <div className="flex items-center gap-2">
              <FaTags className="text-yellow-500 text-xl" />
              <span className="text-sm text-gray-800">100% BRAND NEW</span>
            </div>
            <div className="flex items-center gap-2">
              <FaTools className="text-yellow-500 text-xl" />
              <span className="text-sm text-gray-800">WARRANTY SERVICES</span>
            </div>
            <div className="flex items-center gap-2">
              <FaShippingFast className="text-yellow-500 text-xl" />
              <span className="text-sm text-gray-800">FREE SHIPPING</span>
            </div>
            <div className="flex items-center gap-2">
              <FaPhoneAlt className="text-yellow-500 text-xl" />
              <span className="text-sm text-gray-800">
                CALL: +971 4 385 9367
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Categories, Search, and Cart - Sticky */}
      <div className={`bg-gray-100 transition-all duration-300 ${isScrolled ? 'fixed top-0 left-0 right-0 z-50 shadow-md' : ''}`}>
        <div className="container mx-auto flex justify-between items-center py-3 px-4">
          {/* Categories Menu */}
          <div className="relative">
            <button 
              onClick={toggleMenu}
              className="flex items-center gap-2 bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition-colors"
            >
              <FaBars />
              Categories
              <MdKeyboardDoubleArrowDown />
            </button>
            <CSSTransition
              in={isMenuOpen}
              timeout={300}
              classNames="menu"
              unmountOnExit
              nodeRef={nodeRef}
            >
              <div ref={nodeRef} className="absolute top-full left-0 bg-white shadow-lg mt-1 w-48 z-10 rounded overflow-hidden">
                <Link to="/category/cameras" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                  <FaCamera className="mr-2 text-yellow-500" />
                  <span>Cameras</span>
                </Link>
                <Link to="/category/lenses" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                  <MdCameraRoll className="mr-2 text-yellow-500" />
                  <span>Lenses</span>
                </Link>
                <Link to="/category/gimbals" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                  <FaGripHorizontal className="mr-2 text-yellow-500" />
                  <span>Gimbals</span>
                </Link>
                <Link to="/category/memory-cards" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                  <FaMemory className="mr-2 text-yellow-500" />
                  <span>Memory Cards</span>
                </Link>
                <Link to="/category/bags" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                  <FaSuitcase className="mr-2 text-yellow-500" />
                  <span>Bags</span>
                </Link>
                <Link to="/category/accessories" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                  <FaCogs className="mr-2 text-yellow-500" />
                  <span>Accessories</span>
                </Link>
              </div>
            </CSSTransition>
          </div>

          {/* Search Bar */}
          <div className="flex-grow mx-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for products ..."
                className="w-full border rounded pl-4 pr-10 py-2.5 text-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-all"
                value={searchQuery}
                onChange={handleSearch}
              />
              <button className="absolute right-2 top-1/2 transform -translate-y-1/2 text-yellow-500 hover:text-yellow-600 transition-colors">
                <FaSearch />
              </button>
              {searchResults.length > 0 && (
                <div className="absolute z-10 bg-white border border-gray-300 mt-1 w-full rounded-md shadow-lg">
                  {searchResults.map(product => (
                    <div
                      key={product.slug}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleProductSelect(product.slug)}
                    >
                      <div className="font-semibold">{product.name}</div>
                      <div className="text-sm text-gray-600">{formatCurrency(product.price)}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Shopping Cart */}
          <Link to="/cart" className="flex items-center gap-2 hover:text-yellow-500 transition-colors">
            <FaShoppingCart className="text-2xl" />
            <span className="text-sm">
              Cart {cart.length}<br /> 
              <span className="font-bold">{formatCurrency(cartTotal)}</span>
            </span>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { AppContext } from '../context/AppContext';

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const {signup, isAuthenticated} = useContext(AppContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    //e.preventDefault();
    // Here you would typically send a request to your backend to register the user
    // For now, we'll just log the data and redirect to the login page
    try {
      const data = await signup({ name, email, password });
      console.log("User account created", data);
      if (data.token) {
        navigate("/account");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-50">
                  <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                      Create your account
                    </h2>
                    
                  </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <div className="bg-white px-6 py-12 shadow-sm sm:rounded-lg sm:px-12">
                    <form action={handleSubmit} method="POST" className="space-y-6">
                      
                    <div>
                        <label htmlFor="text" className="block text-sm/6 font-medium text-gray-900">
                          Full Name
                        </label>
                        <div className="mt-2">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            autoComplete="name"
                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                          />
                        </div>
                      </div>
                      
                      <div>
                        <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoComplete="email"
                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                          />
                        </div>
                      </div>
      
                      <div>
                        <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                          Password
                        </label>
                        <div className="mt-2">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            autoComplete="current-password"
                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                          Confirm password
                        </label>
                        <div className="mt-2">
                          <input
                            id="confirmPassword"
                            name="password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            autoComplete="current-password"
                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 border border-gray-300 placeholder:text-gray-400 focus:border-amber-300 focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 focus:outline-none sm:text-sm/6"
                          />
                        </div>
                      </div>
      
                
      
                          
      
                        <div>
                          <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-amber-400 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-amber-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Register
                          </button>
                          {error && <p className="white-bg text-center mt-2 text-red-500">{error}</p>}
                        </div>
                      </form>
      
                      <div>
                        <div className="relative mt-10">
                          <div aria-hidden="true" className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-200" />
                          </div>
                          <div className="relative flex justify-center text-sm/6 font-medium">
                            <span className="bg-white px-6 text-gray-900">Or continue with</span>
                          </div>
                        </div>
      
                        <div className="mt-6 grid grid-cols-2 gap-4">
                          <a
                            href="#"
                            className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50 focus-visible:ring-transparent"
                          >
                            <FcGoogle size={24} className="inline-block" />
                            <span className="text-sm/6 font-semibold">Google</span>
                          </a>
      
                          <a
                            href="#"
                            className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50 focus-visible:ring-transparent"
                          >
                            <FaFacebook size={24} className="inline-block" />
                            <span className="text-sm/6 font-semibold">Facebook</span>
                          </a>
                        </div>
                      </div>
                    </div>
      
                    <p className="mt-10 text-center text-sm/6 text-gray-500">
                      Already have an account?{' '}
                      <a href="/login" className="font-semibold text-amber-600 hover:text-amber-500">
                        Login
                      </a>
                    </p>
                  </div>
                </div>
    </>
  );
};

export default RegisterPage;
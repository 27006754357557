import React from 'react';

const ShippingReturns = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Shipping & Returns</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Shipping Information</h2>
        <p className="mb-4">We currently only ship to the United Arab Emirates.</p>
        <ul className="list-disc list-inside mb-4">
          <li>Free shipping on orders over AED 200</li>
          <li>Standard shipping (3-5 business days): AED 20</li>
          <li>Express shipping (1-2 business days): AED 50</li>
        </ul>
        <p>Please note that delivery times may vary depending on your location within the UAE.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Returns Policy</h2>
        <p className="mb-4">We want you to be completely satisfied with your purchase. If you're not happy with your order, you can return it within 30 days of receipt for a full refund or exchange.</p>
        <h3 className="text-xl font-semibold mb-2">Return Process:</h3>
        <ol className="list-decimal list-inside mb-4">
          <li>Contact our customer service team to initiate a return</li>
          <li>Pack the item securely in its original packaging</li>
          <li>Include the original receipt or proof of purchase</li>
          <li>Ship the item back to our returns address (provided upon return initiation)</li>
        </ol>
        <p className="mb-4">Please note that returned items must be in their original condition, unworn, and with all tags attached. The cost of return shipping is the responsibility of the customer unless the item is defective or we made an error in your order.</p>
        <p>Refunds will be processed within 5-7 business days after we receive your return.</p>
      </section>
    </div>
  );
};

export default ShippingReturns;